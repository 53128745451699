import React, { Component } from "react";

import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import { Table, Row, Col, Media, Button, Card } from "reactstrap";

import swal from "sweetalert";
import { trackPackage } from "../Services/tracking.service";

import Styles from "../styles/Pages/TrackingStyles.module.css";

class Tracking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStatus: "",
      statusTag: "",
      statusMessage: "",
      statuses: [],
      orderDetail: {
        consigneeCountry: "",
        consigneeCity: "",
        consignorCountry: "",
        consignorCity: "",
      },
      trackingNumber: "",
      isLoaded: false,
    };
  }

  async getCountry(countryCode) {
    // const response = await fetch("/assets/data/country.json");
    // const countryList = await response.json();

    // let countryName = "";
    // countryList.map((x) => {
    //   if (x.alpha3 == countryCode) {
    //     countryName = x.name;
    //   }
    // });

    // return countryName;
    return countryCode;
  }

  async componentDidMount() {
    const result = await trackPackage(this.props.match.params.referenceNumber);
    console.log(result);
    // return;
    if (result.success) {
      this.state.trackingNumber = this.props.match.params.referenceNumber;
      let stateInfo = { ...this.state, ...result.data };
      let { consignorCountry, consigneeCountry } = stateInfo.orderDetail;
      stateInfo.orderDetail.consigneeCountry = await this.getCountry(
        consigneeCountry
      );
      stateInfo.orderDetail.consignorCountry = await this.getCountry(
        consignorCountry
      );

      stateInfo.isLoaded = true;

      // stateInfo.checkpoints = (stateInfo.checkpoints.length <= 0) ? this.state.checkpointsx : stateInfo.checkpoints;
      console.log(`State =>`, stateInfo);
      this.setState(stateInfo);
    } else {
      swal({
        type: "error",
        title: "ERROR",
        text: "Invalid Tracking ID",
        // icon: "warning",
      });
    }
  }

  createStatusList = () => {
    let statusList = this.state.statuses.map((checkpoint) => {
      return (
        <>
          <div className="row">
            <div className="col-3 col-lg-3 mt-5">
              <div className={`${Styles.date_time_wrapper}`}>
                <span style={{ fontSize: "13px", color: "black" }}>
                  {new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(checkpoint.updatedTime))}
                </span>
                
                <br />
                
                <span style={{ fontSize: "13px", color: "black" }}>
                  {new Intl.DateTimeFormat("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  }).format(new Date(checkpoint.updatedTime))}
                </span>
              </div>
            </div>
           
            <div className="col-1 col-lg-1 mt-5" 
              style={{
                background: "none",
                position: 'relative'
              }}>

              <div className={Styles.piller}></div>
              <img
                className={Styles.check_icon}
                src="../../assets/img/icons/checked(3).png"
              />

              {/* <div 
                style={{ 
                  paddingTop: 30, 
                  position: "relative",
                  background: "none",
                  marginLeft: 10,
                  marginRight: 10,
                  width: '25%'
                }}
                  className="d-flex justify-content-center"
                >
                <div
                  className=""
                  style={{
                    top: "3px",
                    width: "8px",
                    height: "115px",
                    bottom: "0px",
                    opacity: 1,
                    position: "absolute",
                    borderRadius: "4px",
                    backgroundColor: "black",
                  }}
                ></div>

                <Media
                  className="img-fluid"
                  style={{ 
                    height: "100%", 
                    color: "#16EB16", 
                    zIndex: "100",
                    width: '100%'
                   }}
                  src="../../assets/img/icons/checked(3).png"
                  alt=""
                />
              </div> */}
            </div>
           
            <div className="col-8 col-lg-8 mt-5" 
              style={{ 
                background: "none"
              }}>
              <p
                className="lead mb-1"
                style={{ color: "black", fontWeight: "bold", background: 'none' }}
                dangerouslySetInnerHTML={{__html:`${checkpoint.status} <br/> ${checkpoint.description || ""}`}}
              >
              </p>
            </div>
          </div>

          {/* <div
            className="d-flex justify-content-left"
            style={{ paddingLeft: "5%", display: "none" }}
          >
            <div style={{ 
              padding: "30px 10px 0px 10px", 
              position: "static", 
              background: 'red',
              display: 'none'
            }}>
                <span style={{ fontSize: "13px", color: "black" }}>
                  {new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  }).format(new Date(checkpoint.updatedTime))}
                </span>
                <br />
                <span style={{ fontSize: "13px", color: "black" }}>
                  {new Intl.DateTimeFormat("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  }).format(new Date(checkpoint.updatedTime))}
                </span>
            </div>

            <div style={{ 
              paddingTop: 30, 
              position: "relative",
              background: "blue",
              marginLeft: 10,
              marginRight: 10,
              width: '25%',
              display: 'none'
            }}
              className="d-flex justify-content-center"
            >
              <div
                className=""
                style={{
                  top: "3px",
                  width: "8px",
                  height: "115px",
                  bottom: "0px",
                  opacity: 0.2,
                  position: "absolute",
                  borderRadius: "4px",
                  backgroundColor: "#f5f5f5",
                  display: 'none'
                }}
              ></div>
              <Media
                className="img-fluid"
                style={{ 
                  height: "30px", 
                  color: "#16EB16", 
                  zIndex: "100",
                  display: 'none'
                 }}
                src="../../assets/img/icons/checked(3).png"
                alt=""
              />
            </div>

            <div style={{ 
              padding: "25px 30px 30px 10px", 
              position: "static",
              background: "green",
              display: 'none'
            }}>
              <p
                className="lead mb-1"
                style={{ color: "black", fontWeight: "bold" }}
                dangerouslySetInnerHTML={{__html:`${checkpoint.status} <br/> ${checkpoint.description || ""}`}}
              >
              </p>
              <p className="h4 mt-0">{checkpoint.location}</p>
            </div>
          </div> */}
        </>
      );
    });

    return statusList;
  };

  getTrackingView() {
    return (
      <div
        className="container"
        style={{ paddingTop: "40px", paddingBottom: "40px" }}
      >
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="row">
              <div className="col-md-12">
                <Card style={{ padding: "48px", margin: "2% 0%" }}>
                  <Row>
                    <Col
                      xs="4"
                      sm="4"
                      md="5"
                      lg="5"
                      xl="5"
                      className="d-flex flex-column align-items-center justify-content-center mx-100"
                    >
                      <p
                        className="display-3 mb-1"
                        style={{ color: "black", fontWeight: "bold" }}
                      >
                        {this.state.orderDetail.consignorCountry || ""}
                      </p>
                      <p className="lead h3 font-bold m-0">
                        {this.state.orderDetail.consignorCity || ""}
                      </p>
                    </Col>
                    <Col
                      xs="4"
                      sm="4"
                      md="2"
                      lg="2"
                      xl="2"
                      className="d-flex align-self-center"
                    >
                      <Card
                        style={{
                          border: "none",
                          width: "80%",
                          height: "80%",
                          marginTop: "10%",
                        }}
                      >
                        <Media
                          object
                          style={{
                            border: "none",
                            maxHeight: "50px",
                            maxWidth: "70px",
                          }}
                          className="img-fluid align-self-center"
                          src="../assets/img/icons/rightArrow.png"
                          alt="pic"
                        />
                      </Card>
                    </Col>

                    <Col
                      xs="4"
                      sm="4"
                      md="5"
                      lg="5"
                      xl="5"
                      className="d-flex flex-column align-items-center justify-content-center mx-100"
                    >
                      <p
                        className="display-3 mb-1"
                        style={{ color: "black", fontWeight: "bold" }}
                      >
                        {this.state.orderDetail.consigneeCountry || ""}
                      </p>
                      <p className="lead h3 font-bold m-0">
                        {this.state.orderDetail.consigneeCity || ""}
                      </p>
                    </Col>
                  </Row>
                </Card>
              </div>
              <div className="col-md-12">
                <div className="card mb-3">
                  <div className="card-body pt-0">
                    <div className="row">
                      <div
                        className="col-md-12 text-white text-center p-4"
                        style={{
                          backgroundColor:
                            this.state.trackingStatus == "Delivered"
                              ? "#00b200"
                              : "#d3d3d3",
                        }}
                      >
                        <div id="trackingStatus m-3">
                          <h1 className="display-2 m-0">
                            {this.state.trackingStatus}
                          </h1>
                        </div>
                        <div id="trackingNumber" className="m-3">
                          <p className="h4 m-0" style={{ marginBottom: "0px" }}>
                            Tracking No. {this.state.trackingNumber}
                          </p>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-8 offset-md-2">
                            {this.createStatusList()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getLoadingView() {
    return (
      <div
        className="container p-4 d-flex justify-content-center align-items-center"
        style={{ height: "50vh" }}
      >
        <div className="row">
          <div className="col-md-12">
            <div
              className="spinner-border"
              style={{ width: "10rem", height: "10rem" }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    // console.log(this.state.eventTime[0]);
    // console.log(this.state.eventTimeString[0])
    return (
      <div className="wrapper" style={{ backgroundColor: "#F3F3F3" }}>
        <Header />

        {this.state.isLoaded ? this.getTrackingView() : this.getLoadingView()}

        <Footer />
      </div>
    );
  }
}
export default Tracking;

import React from 'react'
import { Media } from 'reactstrap';

import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
class Aboutus extends React.Component {
    render() {
        return (
            <div className="Wrapper">
                <Header />
                
                    <div className="container">

                        {/* header section h1 and all */}
                        <section className="pad-50">
                            <div className="theme-container container ">
                                <div className="row">
                                    <div className="col-sm-8 pull-left">
                                        <div className="title-wrap">
                                            <h2 className="section-title no-margin"> About us </h2>
                                            <p className="fs-16 no-margin" style={{ fontWeight: "bold" }}> Get to know us better </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>

                        {/* end of header section  */}

                        {/* about us section */}

                        <div className="row">
                            <div className="col-lg-4 d-none d-lg-block">
                                <Media object src={"../../assets/img/about1.png"} />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-8 mt-5">
                                <h2 style={{ fontWeight: "bold", color: "black" }}>Hello, welcome to GTV...</h2>
                                <h3 style={{ fontWeight: "bold", color: "black" }}>Who are we?</h3><br />
                                
                            <p style={{ fontSize: "15px" }}>Since its inception in 2009, GTV has become Sri Lanka’s
                            leading International courier services company. Our vision is to become the platform for
                            crossborder and local commerce in Sri Lanka, through a combination of world-class infrastructure,
                             logistics operations of the highest quality and cutting-edge technology capabilities.</p>
                            </div><br />
                        </div>
                        

                        {/* end of about us section  */}
                    </div>
                <hr/>
                <Footer />
            </div>
        );
    }
}
export default Aboutus
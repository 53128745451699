import React from 'react'
import { Media } from 'reactstrap';

import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header';
class Services extends React.Component {
    render() {
        return (
            <div className="wrapper">
                <Header/>
                <div className="container">

                    {/* header section h1 and all */}

                    <section className="pad-50">
                        <div className="theme-container container ">
                            <div className="row">
                                <div className="col-sm-8 pull-left">
                                    <div className="title-wrap">
                                        <h2 className="section-title no-margin"> Services </h2>
                                        <p className="fs-16 no-margin"> </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                    {/* end of header section */}

                    {/* services section */}
                    
                    <div className="row">
                        <div className="col-lg-3 d-none d-lg-block">
                            <Media src="assets/img/services/int-ex.png" className="" alt="..." style={{ width: "80%" }}/>
                        </div><br/>
                        <div style={{ color: "black" }} className="col-lg-9 mt-4">
                            <h3>International Express</h3><br />
                            <p className="text-justify">For time-sensitive overseas delivery of your parcels and letters, choose our GTV International Express Delivery Services. Delivered within 1 to 4 business days with availability to more than 200 countries and territories worldwide. You benefit from our established partnerships with all of the worldwide express courier companies, from DHL and DPD to Fedex, TNT and UPS.  </p>
                        </div>

                    </div>
                    <br/>
                    
                    < div className="row" >
                        <div className="col-lg-3 d-none d-lg-block">
                            <Media src="assets/img/services/int-ec.png" className="" alt="..." style={{ width: "80%" }}/>
                        </div>
                        <div style={{ color: "black" }} className="col-lg-9 mt-4">
                            <h3>International Economy</h3><br />
                            <p className="text-justify">For Cost effective and non-urgent overseas delivery of your parcels and letters, choose our GTV International Economy Delivery Services. Delivered within 4 to 10 business days with availability to more than 200 countries and territories worldwide. </p>
                        </div>
                    </div >
                    <br/>

                    <div className="row">
                        <div className="col-lg-3 d-none d-lg-block">
                            <Media src="assets/img/services/int-col.png" className="" alt="..." style={{ width: "80%" }}/>
                        </div>
                        <div style={{ color: "black" }} className="col-lg-9 mt-4">
                            <h3>International Collection</h3><br />
                            <p className="text-justify">For very cost effective and affordable international parcel collection service from our reliable international agents and partners collection and access points. Weekly consolidated to reduce your transport logistics cost and gain more discounts. </p>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-lg-3 d-none d-lg-block">
                            <Media src="assets/img/services/int-air.png" className="" alt="..." style={{ width: "80%" }}/>
                        </div>
                        <div style={{ color: "black" }} className="col-lg-9 mt-4">
                            <h3>Air Freight </h3><br />
                            <p className="text-justify">GTV Air Freight works with major air freight carriers, including Sri Lankan Airlines, Air India Cargo, Qatar Airways and Emirates SkyCargo.  Our links with those and many others mean you can receive the service you want: the fastest possible transit times at the lowest possible prices. With flexible express or economy air freight services which allow you to import and export quickly from one country to another with customs clearance.</p>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-lg-3 d-none d-lg-block">
                            <Media src="assets/img/services/int-sea.png" className="" alt="..." style={{ width: "80%" }}/>
                        </div>
                        <div style={{ color: "black" }} className="col-lg-9 mt-4">
                            <h3>Sea Freight</h3><br />
                            <p className="text-justify">With GTV Sea Freight service you get the benefits of reliable sailing times and competitive rates to get your cargo where it needs to be, when it needs to be there. Whether it's less-than-container loads (LCL), full container loads (FCL) or over-sized cargo. </p>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-lg-3 d-none d-lg-block">
                            <Media src="assets/img/services/int-cus.png" className="" alt="..." style={{ width: "80%" }}/>
                        </div>
                        <div style={{ color: "black" }} className="col-lg-9 mt-4">
                            <h3>Customs Clearance</h3><br />
                            <p className="text-justify">Whether you're shipping air, ocean, or ground, we'll help you reduce the risk of delays or penalties. Clearing Sri Lanka Customs is an essential step in the importing process; if your goods can’t clear customs, they can’t enter the Country. Our dedicated team of experts are fluent in customs clearance, trade management and global logistics, and can scale up as your needs increase.</p>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-lg-3 d-none d-lg-block">
                            <Media src="assets/img/services/express.png" className="" alt="..." style={{width:"80%"}}/>
                        </div>
                        <div style={{ color: "black" }} className="col-lg-9 mt-4">
                            <h3>Domestic Delivery & Transport</h3><br />
                            <p className="text-justify">Whether its road freight and courier networks means that we are ideally placed to help you with your e-commerce or wholesale distribution of goods. With branches all over the island we can represent your brand securely with time critical, high value and reliable delivery of Domestic Island-wide parcels, freight or confidential documents. </p>
                        </div>
                    </div>
                    <br />
                   

                    {/* end of services section */}
                   
                </div><hr/>
                <Footer/>
            </div>
        );
    }
}
export default Services








    
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import { useLocation, useHistory } from "react-router";
import { Media } from "reactstrap";
import { getPaymentStatus, getBaseUrl, getPayhereCreds } from "../Services/tracking.service";

function makeId(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export default () => {
  console.log("Payment form");

  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const [first_name, last_name] = params.get("name").split(" ");

  const initialFormValues = {
    email: params.get("email"),
    first_name,
    last_name,
    phone: params.get("phone"),
    address: params.get("address"),
    city: params.get("city"),
    country: "Sri Lanka",
  };

  const id = params.get("id");

  console.log({ params, initialFormValues });

  const [validated, setValidated] = useState(false);
  const [formDetails, setFormDetails] = useState({
    amount: 1000,
    ...initialFormValues,
  });

  const [status, setStatus] = useState();
  const [error, setError] = useState("fnjidsafdsaf");
  const [order_id,setOrderId] = useState();
  var baseUrl = getBaseUrl();
  useEffect(()=>{
    setOrderId(makeId(32))
  },[])

  var payhereDetails = getPayhereCreds();

  var payment = {
    order_id : order_id,
    notify_url: `${baseUrl}api/app/hooks/payhere-notification`,
    custom_1: id,
    ...formDetails,
    ...payhereDetails
  };
  console.log(location.state); // it is equal to yourData

  // Called when user completed the payment. It can be a successful payment or failure
  window.payhere.onCompleted = function onCompleted(orderId) {
    setStatus("PROCESSING");
    console.log("Payment completed. OrderID:" + orderId);
    //Note: validate the payment and show success or failure page to the customer
  };

  // Called when user closes the payment without completing
  window.payhere.onDismissed = function onDismissed() {
    //Note: Prompt user to pay again or show an error page
    console.log("Payment dismissed");
  };

  // Called when error happens when initializing payment such as invalid parameters
  window.payhere.onError = function onError(error) {
    // Note: show an error page
    console.log("Error:" + error);
    setError(error);
  };

  function pay() {
    window.payhere.startPayment(payment);
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    event.preventDefault();
    event.stopPropagation();
    pay();
  };

  const onChangeValue = (field, event) => {
    console.log({ field, event });
    const value = event.target.value;
    const newFormDetails = formDetails;
    newFormDetails[field] = value;
    setFormDetails(newFormDetails);
  };

  const FormField = ({ field, fieldName, disabled, noChange, value }) => {
    return (
      <div className="col">
        <Form.Group>
          <Form.Label>
            {fieldName} <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            value={value ? value : formDetails[field]}
            onChange={noChange ? (e) => {} : (e) => onChangeValue(field, e)}
            disabled={disabled}
            required
          />
        </Form.Group>
      </div>
    );
  };

  console.log(formDetails);

  if (status === "PROCESSING") {
    return (
      <PaymentProcessing
        setStatus={setStatus}
        setError={setError}
        shipmentId={order_id}
      />
    );
  }

  if (status === "COMPLETED") {
    return <PaymentCompleted setStatus={setStatus} setError={setError} />;
  }

  if (status === "ERROR") {
    return (
      <PaymentError setStatus={setStatus} setError={setError} error={error} />
    );
  }

  return (
    <>
      <Header />
      <p>{JSON.stringify(location.state)}</p>
      <Form onSubmit={handleSubmit} validated={validated}>
        <section className="container">
          <h3 style={{ textAlign: "center", color: "white" }}>{"."}</h3>
          <h3 style={{ color: "#8E211A" }}>Payment</h3>
          <div
            style={{
              color: "black",
              background: "#f9f9f9",
              border: "1px solid gray",
              borderRadius: "5px",
              padding: "40px 20px",
            }}
          >
            <div className="row">
              {/* <FormField
                field="shipmentId"
                fieldName="Shipment ID"
                disabled
                noChange={true}
                value={id}
              /> */}
              <FormField
                field="amountShown"
                fieldName="Amount (SLR)"
                disabled
                noChange={true}
                value={"1000"}
              />
            </div>

            <Form.Label
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                fontWeight: "500",
              }}
            >
              Customer details
            </Form.Label>
            <div className="row">
              <FormField field="first_name" fieldName="First name" />
              <FormField field="last_name" fieldName="Last name" />
            </div>
            <div className="row">
              <FormField field="phone" fieldName="Phone number" />
              <FormField field="email" fieldName="Email" />
            </div>
            <div className="row">
              <FormField field="address" fieldName="Address" />
              <FormField field="city" fieldName="City" />
            </div>
          </div>
          <button
            style={{
              fontSize: "15px",
              color: "white",
              width: "200px",
              float: "right",
            }}
            type="submit"
            className="btn-1"
          >
            Proceed to payment
          </button>
        </section>
      </Form>
      <Footer />
    </>
  );
};

const PaymentProcessing = ({ setError, setStatus, shipmentId }) => {
  React.useEffect(() => {
    const interval = setInterval(async () => {
      var paymentResp = await getPaymentStatus(shipmentId);
      debugger;
      if(!paymentResp) return;

      if (paymentResp.status === 1 || paymentResp.status === 0) {
        setStatus("COMPLETED");
      } else if (paymentResp.status === -1) {
        setError(paymentResp.statusMessage || `Error occured while processing your payment, Payment Reference - ${shipmentId}`);
        setStatus("ERROR");
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{
        color: "black",
        background: "#f9f9f9",
        border: "1px solid gray",
        borderRadius: "5px",
        padding: "400px 20px",
        height: 1000,
      }}
    >
      <div style={{}}>
        <div class="loader"></div>
        <div
          style={{
            textAlign: "center",
            marginTop: "10px",
            fontSize: "1.2em",
          }}
        >
          Processing payment...
        </div>
      </div>
    </div>
  );
};



const PaymentCompleted = () => {
  return (
    <div
      style={{
        color: "black",
        background: "#f9f9f9",
        border: "1px solid gray",
        borderRadius: "5px",
        padding: "400px 20px",
        height: 1000,
      }}
    >
      <div style={{}}>
        <div
          style={{
            alignContent: "center",
            textAlign: "-webkit-center",
            marginTop: "10px",
            fontSize: "1.2em",
          }}
        >
          {" "}
          <Media
            className="img-fluid"
            style={{ height: "100px", color: "#16EB16", zIndex: "100" }}
            src="../../assets/img/icons/checked(3).png"
            alt=""
          />
          Payment successful
          <ReturnToHomeButton />
        </div>
      </div>
    </div>
  );
};

const PaymentError = ({ error }) => {
  return (
    <div
      style={{
        color: "black",
        background: "#f9f9f9",
        border: "1px solid gray",
        borderRadius: "5px",
        padding: "400px 20px",
        height: 1000,
      }}
    >
      <div style={{}}>
        <div
          style={{
            alignContent: "center",
            textAlign: "-webkit-center",
            marginTop: "10px",
            fontSize: "1.2em",
          }}
        >
          {" "}
          <h2 style={{ color: "#8E211A" }}>Payment Error!</h2>
          <h3>{error}</h3>
          <ReturnToHomeButton />
        </div>
      </div>
    </div>
  );
};


const ReturnToHomeButton = () => {
  const history = useHistory();
  return (
    <button
      style={{
        fontSize: "15px",
        color: "white",
        width: "200px",
        position: "absolute",
        bottom: 10,
        right: 30,
      }}
      className="btn-1"
      onClick={() => history.push("/")}
    >
      Return to home
    </button>
  );
};
import React from 'react'
// import { BrowserRouter as Link } from "react-router-dom";


import Header from '../Components/Header/Header'
import Footer from '../Components/Footer/Footer'
class Contact extends React.Component {
   render() {
      return (
      

              
         <div className="wrapper">
            <Header/>
            <section className="pt-50 pb-120 error-wrap">
               <div className="theme-container container text-center">
                  <h2 className="section-title no-margin"> 404 </h2>
                  <p className="fs-20">The page you are looking for is not found!<br />
                     Please use the correct link.</p>
                  <h3 className="no-margin pt-10"> <a href="/" className="title-1 home"><i className="arrow_left fs-20"></i> go back to home </a> </h3>
               </div>
            </section>
            <Footer/>
         </div>


      
      );
   }
}
export default Contact
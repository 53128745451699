import React from 'react'
// import { Media } from 'reactstrap';

import Header from '../Components/Header/Header'
import Footer from '../Components/Footer/Footer'

class Locations extends React.Component {
   render() {
      return (
         <div className="wrapper">
            <Header/>
            <div className="Container-fluid">
               <div className="jumbotron">
                  <div className="container">
                     <h1 className="d-flex justify-content-center" style={{ color: "black" }}>Find a courier & parcel office near you.</h1><hr />
                     <p style={{ color: "black", fontWeight: "400" }}>We’re headquartered in Colombo, but one of our local offices might be close to any location in Sri Lanka. Find your nearest
                         office below or contact us at <a href="tel:+94114654444" style={{fontWeight:"bold"}}>+ 94 11 465 44 44</a> and we’ll get you directed to the right resource for your courier & parcel needs.</p>
                  </div>
               </div>
               <div className="container">
                  <div>
                     <div>
                        <h1 style={{ color: "black", fontWeight: "bold"}}>Local Offices</h1><br />
                        <div>
                           <h3 style={{ color: "#8E211A", fontWeight:"bold" }}>Colombo</h3>
                           <h4 style={{ color: "black", fontWeight: "bold" }}>Colombo 06 <br/>
                              No 18/3 Dr. E.A. Cooray<br/>
                              Mawatha. Colombo 06.<br />
                              011 2360926<br />
                              011 4378161<br />
                           </h4><br />
                           <h4 style={{ color: "black", fontWeight: "bold" }}>
                              Wellawatte<br />
                              No. 513 Galle Road<br />
                              Colombo 06<br />
                              011 4378160<br />
                           </h4><br/>
                           <h4 style={{ color: "black", fontWeight: "bold" }}>
                              Kotahena<br />
                              No. 253A George R.De Silva<br />
                              Mawatha. Colombo 13<br />
                              011 4378163<br />
                           </h4><br />
                        </div><br />
                        <div>
                           <h3 style={{ color: "#8E211A", fontWeight: "bold"}}>Gampaha</h3>
                           <h4 style={{ color: "black", fontWeight: "bold"  }}>No. G-2 146 Negombo Road<br />
                              (Pearl Park) Wattala<br/>
                              011 4328927<br />
                           </h4>
                        </div><br />
                        <div>
                           <h3 style={{ color: "#8E211A", fontWeight: "bold"}}>Kandy</h3>
                           <h4 style={{ color: "black", fontWeight: "bold"}}>No. 258/A D.S Senanayake<br />
                              Veediya. Kandy<br />
                              081 4946400<br />
                           </h4>
                        </div><br />
                        <div>
                           <h3 style={{ color: "#8E211A", fontWeight: "bold"}}>Jaffna</h3>
                           <h4 style={{ color: "black", fontWeight: "bold"}}>No.225 Kasthuriyar Road<br />
                              Jaffna<br />
                              021 4925024<br />
                              021 2224338<br /><br />
                              No. 81A Point Pedro Road<br />
                              Nelliyadi<br />
                              021 4930099<br />
                           </h4>
                        </div><br />
                        <div>
                           <h3 style={{ color: "#8E211A", fontWeight: "bold"}}>Batticaloa</h3>
                           <h4 style={{ color: "black", fontWeight: "bold"}}>No. 504/3 Trincomalee Road<br/>
                              Batticaloa<br />
                              065 4926837<br />
                           </h4>
                        </div><br />
                        <div>
                           <h3 style={{ color: "#8E211A", fontWeight: "bold"}}>Trincomalee</h3>
                           <h4 style={{ color: "black", fontWeight: "bold"}}>No.49 Samthu Lane<br/>
                              Trincomalee<br />
                              026 4931068<br />
                           </h4>
                        </div><br />
                        <div>
                           <h3 style={{ color: "#8E211A", fontWeight: "bold"}}>Vavuniya</h3>
                           <h4 style={{ color: "black", fontWeight: "bold"}}>No. 25 Kandasami Kovil Road<br/>
                              Vavuniya<br />
                              024 4930200<br />
                           </h4>
                        </div><br />
                        <div>
                           <h3 style={{ color: "#8E211A", fontWeight: "bold"}}>Kilinochchi</h3>
                           <h4 style={{ color: "black", fontWeight: "bold"}}>No. A9 Road Killinochchi<br/>
                              (Front of Kandasami Kovil)<br />
                              021 4930330<br />
                           </h4>
                        </div><br />
                     </div>
                     {/* <div>
                        <h1 style={{ color: "black", fontWeight: "bold"}}>International Agents</h1><br/>
                        <div>
                           <div>
                              <h3 style={{ color: "#8E211A", fontWeight: "bold" }}>United Kingdom</h3>
                              <h4 style={{ color: "black", fontWeight: "bold" }}>No 513 High Road<br/>
                                 Wembley<br />
                                 HA0 2ND<br />
                                 UK<br />
                                 T: 0042089002451<br />
                              </h4>
                           </div><br />
                           <div>
                              <h3 style={{ color: "#8E211A", fontWeight: "bold"}}>Canada</h3>
                              <h4 style={{ color: "black", fontWeight: "bold"}}>Collection centre<br/>
                                 151 Nashdene Road<br />
                                 Unit 16 Toronto<br />
                                 Ontario M1V 4C4<br />
                                 Canada<br />
                                 T: 0014162920444<br />
                              </h4>
                           </div><br />
                           <div>
                              <h3 style={{ color: "#8E211A", fontWeight: "bold"}}>Switzerland</h3>
                              <h4 style={{ color: "black", fontWeight: "bold"}}>Collection centre<br/>
                                 Luisen Strasse 21,<br />
                                 8005 Zurich<br />
                                 Switzerland<br />
                                 T: 0041 438167012,<br />
                                 0041763841580<br />
                              </h4>
                           </div><br />
                        </div>
                     </div> */}
                  </div>
               </div>
               </div>
            <Footer/>
         </div>
      );
   }
}
export default Locations









import React, { Component } from 'react';
// import { BrowserRouter as Link } from "react-router-dom";

class Header extends Component {
    render() {
        return (
            <header className="header-main header-style-one" style={{ position: "relative", zIndex: "999" }}>

                <div className="top-bar font2-title1 white-clr">
                    <div className="theme-container container-fuild">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 ">
                                <p style={{ float: "left" }} className="contact-num">
                                    <i className="fa fa-phone"></i><span> </span>
                                    Call us now: <span itemProp="telephone" className="theme-clr"> <a href="tel:+94114654444">+94 11 4654444</a> </span>
                                </p>
                            </div>

                        </div>

                        {/* <a href="https://track.parcellk.com/login" className="sign-in theme-clr-bg"> sign In </a> */}
                    </div>
                </div>

                <nav className="menu-bar pt-4 pb-4 font2-title1 black-clr d-flex justify-content-center scrolling-navbar" style={{ borderRadius: "unset" }}>
                    <div className="theme-container container">
                        <div className="row">
                            <div className="col-md-2 col-sm-12 col-lg-2">
                                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-controls="navbar">
                                    <i style={{ fontSize: "24px" }} className="fa fa-bars"></i>
                                </button>
                                <div>
                                    <a className="navbar-logo App" href="/"><img style={{width:"60px"}} src="assets/img/logo/GTVLogo.png" alt="Logo" /> </a>
                                </div>
                            </div>

                            <div className="col-md-10 col-sm-10 col-lg-10 d-flex align-items-center">
                                <div id="navbar" className="collapse navbar-collapse clearfix" aria-expanded="false" style={{ height: "1px" }}>
                                    <ul className="navbar-nav theme-menu" >
                                        <li> <a href="/" className="Home">Home</a> </li>
                                        <li> <a href="/aboutus" className="Aboutus">About Us</a> </li>
                                        <li> <a href="/services" className="Services">Services</a> </li>
                                        <li> <a href="/locations" className="Locations">Locations</a> </li>
                                        <li> <a href="/faq" className="FAQ">Support & FAQ</a> </li>
                                        <li className="pt-2 pl-3 ml-3"> <a href="/contact" className="Contact">Contact Us</a></li>
                                        {/* <li className="pt-2 pl-3 ml-3"> <a><Link to="/signup" className="Register"> Signup </Link> </a></li> */}
                                   </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </nav>

            </header>
        )
    }
}

export default Header;
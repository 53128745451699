import React from 'react'
import { BrowserRouter as Link } from "react-router-dom";


import Header from '../Components/Header/Header'
import Footer from '../Components/Footer/Footer'
class TermsAndConditions extends React.Component {
    render() {
        return (
            <div className="wrapper">
                <Header />
                <div className="container">

                    {/* header section h1 and all */}

                    <section className="pad-50">
                        <div className="theme-container container ">
                            <div className="row">
                                <div className="col-sm-8 pull-left">
                                    <div className="title-wrap">
                                        <h2 className="section-title no-margin"> Terms and Conditions </h2>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                    {/* end of header section */}

                    {/* details area */}

                    <div className="row" style={{color:'black'}}>
                        
                <b style={{marginTop:'10px'}}>CONDITIONS OF CARRIAGE</b>
                In tendering the shipment for carriage, the customer agrees to these terms and conditions of carriage and that this air bill is NON-NEGOTIABLE
                and has been prepared by the customer or on the customer&#39;s behalf by PARCEL.LK. As used in these conditions, PARCEL.LK includes PARCEL
                LANKA (PVT)LTD, all operating divisions and subsidiaries of PARCEL LANKA (PVT)LTD. and their respective agents, servants, officers and
                employees.

                <b style={{marginTop:'10px'}}>1. SCOPE OF CONDITIONS</b>
                These conditions shall govern and apply to all services provided by PARCEL.LK, BY SIGNING THIS AIRBILL, THE CUSTOMER ACKNOWLEDGES
                THAT HE/SHE HAS READ THESE CONDITIONS AND AGREES TO BE BOUND BY EACH OF THEM. PARCEL.LK shall not be bound by any agreement
                which varies from these conditions, unless such agreement is in writing and signed by an authorized officer of PARCEL.LK. In the absence of
                such written agreement, these conditions shall constitute the entire agreement between PARCEL.LK and each of its customers. NO employee of
                PARCEL.LK shall have the authority to alter or waive these terms and conditions, except as stated herein.

                <b style={{marginTop:'10px'}}>2. PARCEL.LK&#39;S OBLIGATIONS</b>
                PARCEL.LK agrees, subject to payment of applicable rates and charges in effect on the date of acceptance by PARCEL.LK of a customer&#39;s
                shipment, to arrange for the transportation of the shipment between the locations agreed upon by PARCEL.LK and the customer.
                PARCEL.LK reserves the right to transport the customer&#39;s shipment by any route and procedure and by successive carriers and according to its
                own handling, storage and transportation methods.

                <b style={{marginTop:'10px'}}>3. SERVICE RESTRICTION</b>
                a) PARCEL.LK reserves the right to refuse any documents or parcels from any person, firm, or company at its own discretion.<br/>
                b) PARCEL.LK reserves the right to abandon carriage of any shipment at any time after acceptance when such shipment could possibly cause
                damage or delay to other shipments, equipment or personnel, or when any such carriage is prohibited by law or is in violation of any of the
                rules contained herein.<br/>
                c) PARCEL.LK reserves the right to open and inspect any shipment consigned by a customer to ensure that it is capable of carriage to the state
                or country of destination within the standard customs procedures and handling methods of PARCEL.LK. In exercising this right, PARCEL.LK does
                not warrant that any particular item to be carried is capable of carriage, without infringing the law of any country or state through which the
                item may be carried.

                <b style={{marginTop:'10px'}}>4. LIMITATION OF LIABILITY</b>
                Subject to Section 5 and 6 hereof:<br/>
                a) PARCEL.LK will be responsible for the customer&#39;s shipment only while it is within PARCEL.LK&#39;S custody and control. PARCEL.LK shall not be
                liable for loss or damage of a shipment while reshipment is out of PARCEL.LK&#39;S custody or control. PARCEL.LK&#39;S LIABILITY IS IN ANY EVENT
                LIMITED TO TWENTY DOLLARS (US$20/=) (DOMESTIC) HUNDRED DOLLARS (US$100/=) (INTERNATIONAL) or its equivalent per shipment unless
                a higher value is declared on the Airway bill at the time of tender and an additional charge is paid for, as assessed and determined by
                PARCEL.LK, for each twenty Dollars (DOM - US$20/=) / (INT - US$100/=) or fraction thereof, by which the insured value designated by the
                customer on the Airway bill exceeds twenty Dollars (DOM - US$20/=) / (INT - US$100/=) per shipment.<br/>
                b) Notwithstanding the foregoing, should the customer, at the time of tender, declare a higher value than twenty Dollars (DOM - US$20.00) /
                hundred dollars (INT - US$100/=) on the Airway bill, PARCEL.LK&#39;S liability shall in any event be limited to the lower of the insured value or the
                amount of any loss or damage actually sustained by the customer.<br/>
                c) The actual value of a shipment shall be ascertained by reference to its replacement, reconstitution or reconstruction value at the time and
                place of shipment, whichever is less, without reference to its commercial utility to the customer or to other items of consequential loss.<br/>
                d) NOTWITHSTANDING ANY OF THE FOREGGOING, THE MAXIMUM INSURED VALUE ON ANY SHIPMENT ACCEPTED BY PARCEL.LK IS FIVE
                THOUSAND DOLLARS (US$5,000.00) AND IN NO EVENT SHALL THE LIABILITY OF PARCEL.LK EXCEED THAT AMOUNT.<br/>

                <b style={{marginTop:'10px'}}>5. CONEQUENTIAL DAMAGES EXCLUDED</b>
                PARCEL.LK SHALL NOT BE LIABLE, IN ANY EVENT,, FOR ANY CONSEQUENTIAL OR SPECIAL OR INCIDENTAL DAMAGE OR OTHER INDIRECT LOSS
                HOWEVER AIRSING, WHETHER OR NOT PARCEL.LK HAD KNOWLEDGE THAT SUCH DAMAGE MIGHT BE INCURRED, INCLUDING, BUT NOT
                LIMITED TO LOSS OF INCOME, PROFITS, INTEREST, ULITITY OR LOSS OF MARKET.

                <b style={{marginTop:'10px'}}>6. LIABILITY NOT ASSUMED</b>
                a) PARCEL.LK shall be not liable for any loss, damage, delay, misdelivery, nondelivery not caused by its own negligence, or for any loss, damage,
                delay, misdelivery or non-delivery caused by:<br/>
                i. the act, default or omission the shipper or consignee or any other party who claims an interest in the shipment.<br/>
                ii. the nature of the shipment or any defect, characteristic, or inherent vice thereof.<br/>
                iii. violation by the shipper or consignee of any term or condition stated herein including, but not limited to, improper or insufficient packing,
                securing, marking or addressing, misdescribing the contents of any shipment or failure to observe any of these rules relating to the shipments
                not acceptable for transportation whether such rules are now or hereafter promulgated by PARCEL.LK.
                iv. Acts of God, perils of the air, enemies, public authorities acting with actual or apparent authority or law, acts or omission of postal, customs
                or other government officials, riots, strikes, or other local disputes, hazard incidents to a state of war, weather conditions, temperature or
                atmospheric changes or conditions, mechanical or other delay, of any aircraft used in providing transportation services or any other cause
                reasonably beyond the control of PARCEL.LK.<br/>
                v. Acts or omissions of any postal service, forwarder, or any other entity to whom a shipment is tendered by PARCEL.LK for transportation,
                regardless of whether the shipper requested or had knowledge of such third party delivery requirement.
                vi. Electrical or magnetic injury, erasure, or other such damage to electronic or photographic images or recordings in any form, or damage due
                to insects or vermin.<br/>
                b) While PARCEL.LK will endeavor to exercise its best efforts to provide expeditious delivery in accordance with regular delivery schedules,
                PARCEL.LK will not under any circumstances be liable for delay in pickup, transportation or delivery of any shipment regardless of the causes of
                such delay

                <b style={{marginTop:'10px'}}>7. MATERIALS NOT ACCEPTABLE FOR TRANSPORT</b>
                a) PARCEL.LK will notify customer from time to time as to certain classes of materials which are not accepted by PARCEL.LK for carriage. It is the
                customer&#39;s responsibility to accurately describe the shipment on this Air bill and to ensure that no material is delivered to PARCEL.LK which has
                been declared to be unacceptable by PARCEL.LK.<br/>
                b) PARCEL.LK will not carry:<br/>
                i. property, the carriage of which is prohibited by any law, regulation or state or local government of any country from, to or through which the
                property maybe carried: and<br/>
                firearms bullion works of art negotiable instruments in bearer form<br/>
                jewelry precious metals precious stones lewd obscene or pornographic material<br/>
                currency stamps deeds hazardous or combustible material<br/>
                cashier&#39;s checks money orders travelers checks industrial carbon and diamonds<br/>
                antiques plants animals<br/>
                c) In the event that any customer should consign to PARCEL.LK any such item, as described above, or any item which the customer has
                undervalued for customs purposes or misdiscribed, whether intentionally or otherwise the customer shall indemnify and hold PARCEL.LK
                harmless from all claims, damages, fines and expenses arising in connection therewith, and PARCEL.LK shall have the right to abandon such
                property and / or release possession of said property to any agent or employee of any national or local government claiming jurisdiction over
                such materials. Immediately upon PARCEL.LK&#39;S obtaining knowledge that such materials infringing these conditions have been turned over to
                PARCEL.LK shall be free to exercise any of its rights reserved to it under this section without incurring liability whatsoever to the customer.<br/>
                <b style={{marginTop:'10px'}}>8. PACKAGING</b>
                The packaging of the customer&#39;s documents or goods for transportation is the customer&#39;s sole responsibility, including the placing of the goods
                or documents in any container which may be supplied by the customer to PARCEL.LK. PARCEL.LK accepts no responsibility for loss or damage to
                documents or goods caused by inadequate or inappropriate packaging. It is the sole responsibility of the customer to address adequately each
                consignment of documents or goods to enable effective delivery to be made. PARCEL.LK shall not be liable for delay in forwarding or delivery
                resulting from the customer&#39;s failure to comply with its obligations in this respect.
                <b style={{marginTop:'10px'}}>9. NEGLIGENCE</b>
                The customer is liable for all losses, damages and expenses arising as a result of its failure to comply with its obligations under this agreement
                as a result of its negligence.
                <b style={{marginTop:'10px'}}>10. CHARGES</b>
                Any rates quoted by PARCEL.LK for carriage are inclusive of local airport taxes, but exclusive of any value added taxes, duties, levies, imposts,
                deposits or outlays incurred in respect of carriage of the customer&#39;s goods. Should the customer indicate by endorsement in the space provided
                on the airbill that the receiver shall be liable for any customs duty, the customer shall be liable for such customs duty in the event of a default in
                payment by the receiver. PARCEL.LK will not be liable for any penalties imposed or loss or damage incurred due to the customer&#39;s documents or
                goods being impounded by customs or similar authorities and the customer hereby indemnifies PARCEL.LK against such penalty or loss.
                <b style={{marginTop:'10px'}}>11. PROPERTY</b>
                PARCEL.LK will only carry documents or goods which are the property of the customer and the customer warrants that it is authorized to accept
                and is accepting these conditions not only on behalf of itself but as agent and on behalf of all other persons who are or may hereafter be
                interested in the documents or goods. The customer hereby undertakes to indemnify PARCEL.LK against any damages, costs and expenses
                resulting from any breach of this warranty.
                <b style={{marginTop:'10px'}}>12. CLAIMS</b>
                ANY CLAIMS AGAINST PARCEL.LK MUST BE SUBMITTED IN WRITING TO THE OFFICE OF PARCEL.LK NEAREST THE LOCATION WHERE THE
                SJHIPMENT WAS ACCEPTED,
                WITHIN SIXTY (60 DAYS) OF THE DATE OF ACCEPTANCE BY PARCEL.LK.
                <b style={{marginTop:'10px'}}>13. NON-DELIVERY OF SHIPMENT</b>
                Notwithstanding the shipper&#39;s instruction to the contrary, the shipper shall be liable for all costs and expenses related to the shipment of the
                package, and for costs incurred in either returning the shipment or warehousing the shipment pending disposition.
                <b style={{marginTop:'10px'}}>14. INSURANCE:</b>
                a) PARCEL.LK maintains cargo liability insurance to the full extent of the liability offered to the shipper.<br/>
                b) At the request of the shipper and upon payment therefore at the then prevailing rates. PARCEL.LK will arrange insurance coverage on behalf
                of the shipper in an amount not exceeding Five Thousand Dollars (5,000.00)<br/>
                c) The insurance cover shall be governed by all the terms and conditions contained in the policy of insurance issued by insurance carrier. A
                certificate evidencing such insurance will be made available to the shipper.<br/>
                d) CONSEQUENTIAL DAMAGES AND LOSS OR DAMAGE RESULTING FROM DELAYS IN TRANSPORTATION ARE NOT COVERED BY ANY SUCH
                POLICY OF INSURANCE.<br/>

                    </div>

                </div><hr/>
                <Footer />
            </div>
        );
    }
}
export default TermsAndConditions
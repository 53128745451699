import React, { Component } from 'react';
// import { BrowserRouter as Link } from "react-router-dom";

class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="footer-main pt-50 black-clr">
                    <div className="theme-container container">
                        <div className="row">
                            <div className="col-md-4 col-sm-6 footer-widget">
                                <ul className="contact-detail title-2">
                                    <li className="wow fadeInUp" data-wow-offset="50" data-wow-delay=".30s" style={{ visibility: "visible" }}>
                                        <span>Address:</span> <p className="gray-clr"> GTV Enterprises Private Limited<br /> No. 18/3
E.A. Cooray Mawatha, Wellawatte, <br /> Colombo 00600. </p>
                                    </li>
                                    <li className="wow fadeInUp" data-wow-offset="50" data-wow-delay=".40s" style={{ visibility: "visible" }} >
                                        <span>Number (call Center): </span> <p className="gray-clr"> +94 114 654 444 </p>
                                    </li>
                                    <li className="wow fadeInUp" data-wow-offset="50" data-wow-delay=".50s" style={{ visibility: "visible" }} >
                                        <span>Email address : </span> <p className="gray-clr"> info@gtventer.com</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-4 col-sm-6 footer-widget">
                                <h2 className="title-1 fw-900">quick links</h2>
                                <ul>
                                    <li> <a href="/services">Services</a> </li>
                                    <li> <a href="/aboutus">About Us</a> </li>
                                    <li> <a href="/contact">Contact Us</a> </li>
                                </ul>
                            </div>
                            <div className="col-md-4 col-sm-6 footer-widget">
                                <h2 className="title-1 fw-900">important links</h2>
                                <ul>
                                    <li> <a href="#">Top Shipping cities</a> </li>
                                    <li> <a href="#">Top shipping countries</a> </li>
                                    <li> <a href="#">Branch locations</a> </li>
                                    <li> <a href="#">Shipping advice</a> </li>
                                    
                                    <h2 className="title-1 fw-900">get in touch</h2>
                                    <ul className="social-icons list-inline">
                                        <li className="wow fadeIn" data-wow-offset="50" data-wow-delay=".20s"> <a href="https://www.facebook.com/gtvlk" className="fa fa-facebook"></a> </li>
                                        <li className="wow fadeIn" data-wow-offset="50" data-wow-delay=".25s"> <a href="https://www.linkedin.com/company/gtv-express-srilanka" className="fa fa-linkedin"></a> </li>
                                        <li className="wow fadeIn" data-wow-offset="50" data-wow-delay=".30s"> <a href="https://www.youtube.com/channel/UCfMARBhVFvMu-XOErcEr27A" className="fa fa-youtube"></a> </li>
                                        <li className="wow fadeIn" data-wow-offset="50" data-wow-delay=".35s"> <a href="https://www.instagram.com/gtvexpress/" className="fa fa-instagram"></a> </li>
                                    </ul>
                                    
                                </ul>
                            </div>
                           
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="theme-container container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <p className="copyrightText"> © Copyright 2020, All rights reserved </p>
                            </div>

                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;
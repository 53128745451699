import React, {} from 'react';
import { Accordion, Card } from 'react-bootstrap';

import Header from '../Components/Header/Header'
import Footer from '../Components/Footer/Footer'


class FAQ extends React.Component {

   render(){
      return (
         <div className="wrapper">
            <Header /><br />
            <div className="faqs">
               <section className="">
                  <div className="container">
                     <div className="row">
                        <div className="col-sm-12">
                           <div className="title-wrap positioing pb-5">
                              <h2 className="section-title no-margin "> Find Any Questions and Informations you have.  </h2>
                              <p className="fs-16 no-margin"> Have any doubts about sending a parcel or a shipment? We have most
                            of the questions answered by our experts below. Still have more? You can contact us at  <a href="tel:+94114654444">+94 11 4654444</a> or
                            send us an email at <a href="mailto:info@gtve.com?Subjeect=I%20Have%20A%20Question" target="-top">info@gtve.com</a> and we'll
                            clear them for you.   </p>
                           </div>
                        </div>

                     </div><br />
                     <div className="">
                        <Accordion defaultActiveKey="0">
                           <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="0" className="d-flex justify-content-start textColor">
                                 1) What is Dimensional or Volumetric Weight?
                           </Accordion.Toggle>
                              <Accordion.Collapse eventKey="0" className="textColor">
                                 <Card.Body>Dimensional weight is the weight which is calculated by the area of the box Simple way is to check L * B * H ( In cm ) / 5000 = Volume Weight. <br />You can use this volumetric weight calculator to check dimensional weight of your parcel.</Card.Body>
                              </Accordion.Collapse>
                           </Card>
                           <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="1" className="d-flex justify-content-start textColor">
                                 2) What are the payment methods in GTV?
                           </Accordion.Toggle>
                              <Accordion.Collapse eventKey="1" className="textColor">
                                 <Card.Body></Card.Body>
                              </Accordion.Collapse>
                           </Card>
                           <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="2" className="d-flex justify-content-start textColor">
                                 3) How to track my parcel?
                           </Accordion.Toggle>
                              <Accordion.Collapse eventKey="2" className="textColor">
                                 <Card.Body></Card.Body>
                              </Accordion.Collapse>
                           </Card>
                           <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="3" className="d-flex justify-content-start textColor">
                                 4) Is it good to send Fragile items?
                           </Accordion.Toggle>
                              <Accordion.Collapse eventKey="3" className="textColor">
                                 <Card.Body></Card.Body>
                              </Accordion.Collapse>
                           </Card>
                           <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="4" className="d-flex justify-content-start textColor">
                                 5) Is there a way to Determine custom duty?
                           </Accordion.Toggle>
                              <Accordion.Collapse eventKey="4" className="textColor">
                                 <Card.Body></Card.Body>
                              </Accordion.Collapse>
                           </Card>
                           <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="5" className="d-flex justify-content-start textColor">
                                 6) Do you provide packing services?
                           </Accordion.Toggle>
                              <Accordion.Collapse eventKey="5" className="textColor">
                                 <Card.Body></Card.Body>
                              </Accordion.Collapse>
                           </Card>
                           <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="6" className="d-flex justify-content-start textColor">
                                 7) What are your office hours?
                           </Accordion.Toggle>
                              <Accordion.Collapse eventKey="6" className="textColor">
                                 <Card.Body></Card.Body>
                              </Accordion.Collapse>
                           </Card>
                           <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="7" className="d-flex justify-content-start textColor">
                                 8) Do you have a list of products that can’t be sent with Select Courier?
                           </Accordion.Toggle>
                              <Accordion.Collapse eventKey="7" className="textColor">
                                 <Card.Body>Yes. Please check our Prohibited Goods section.</Card.Body>
                              </Accordion.Collapse>
                           </Card>
                           <Card>
                              <Accordion.Toggle as={Card.Header} eventKey="8" className="d-flex justify-content-start textColor">
                                 9) Do you offer insurance?
                           </Accordion.Toggle>
                              <Accordion.Collapse eventKey="8" className="textColor">
                                 <Card.Body></Card.Body>
                              </Accordion.Collapse>
                           </Card>
                        </Accordion>
                     </div>
                  </div>
               </section>
            </div>
            <Footer />
         </div>
      )
   }
}

export default FAQ;
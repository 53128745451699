import React from "react";
// import { Media } from 'reactstrap';
import swal from "sweetalert";

import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import {Tabs, Tab, Form, Row, Col,ButtonGroup, Button,Radio} from 'react-bootstrap'
import { trackPackage } from "../Services/tracking.service";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      images: [
        "../../assets/img/pattern/slider-2.png",
        "../../assets/img/pattern/slider-3.png",
        "../../assets/img/pattern/slider-4.png",
      ],
      districts : [
        "Ampara",
        "Anuradhapura",
        "Badulla",
        "Batticaloa",
        "Colombo",
        "Galle",
        "Gampaha",
        "Hambantota",
        "Jaffna",
        "Kalutara",
        "Kandy",
        "Kegalle",
        "Kilinochchi",
        "Kurunegala",
        "Mannar",
        "Matale",
        "Matara",
        "Monaragala",
        "Mullaitivu",
        "Nuwara Eliya",
        "Polonnaruwa",
        "Puttalam",
        "Ratnapura",
        "Trincomalee",
        "Vavuniya"
      ],
      domesticAreas :[
        "Colombo",
        "Colombo Suburbs",
        "Gampaha",
        "Kalutara",
        "Jaffna",
        "Kilinochchi",
        "Mullaitivu",
        "Mannar",
        "Vavuniya",
        "Anuradhapura",
        "Polonnaruwa",
        "Putlam",
        "Kurunagala",
        "Galle",
        "Matara",
        "Hambantota",
        "Kegalla",
        "Ratnapura",
        "Badulla",
        "Monaragala",
        "Matale",
        "Kandy",
        "Nuwara Eliya",
        "Ampara",
        "Batticaloa",
        "Trinco"
      ],
      countries:[
        {code:"AFG",name:"Afghanistan"},
        {code:"ALA",name:"Åland Islands"},
        {code:"ALB",name:"Albania"},
        {code:"DZA",name:"Algeria"},
        {code:"ASM",name:"American Samoa"},
        {code:"AND",name:"Andorra"},
        {code:"AGO",name:"Angola"},
        {code:"AIA",name:"Anguilla"},
        {code:"ATA",name:"Antarctica"},
        {code:"ATG",name:"Antigua and Barbuda"},
        {code:"ARG",name:"Argentina"},
        {code:"ARM",name:"Armenia"},
        {code:"ABW",name:"Aruba"},
        {code:"AUS",name:"Australia"},
        {code:"AUT",name:"Austria"},
        {code:"AZE",name:"Azerbaijan"},
        {code:"BHS",name:"Bahamas"},
        {code:"BHR",name:"Bahrain"},
        {code:"BGD",name:"Bangladesh"},
        {code:"BRB",name:"Barbados"},
        {code:"BLR",name:"Belarus"},
        {code:"BEL",name:"Belgium"},
        {code:"BLZ",name:"Belize"},
        {code:"BEN",name:"Benin"},
        {code:"BMU",name:"Bermuda"},
        {code:"BTN",name:"Bhutan"},
        {code:"BOL",name:"Bolivia, Plurinational State of"},
        {code:"BES",name:"Bonaire, Sint Eustatius and Saba"},
        {code:"BIH",name:"Bosnia and Herzegovina"},
        {code:"BWA",name:"Botswana"},
        {code:"BVT",name:"Bouvet Island"},
        {code:"BRA",name:"Brazil"},
        {code:"IOT",name:"British Indian Ocean Territory"},
        {code:"BRN",name:"Brunei Darussalam"},
        {code:"BGR",name:"Bulgaria"},
        {code:"BFA",name:"Burkina Faso"},
        {code:"BDI",name:"Burundi"},
        {code:"KHM",name:"Cambodia"},
        {code:"CMR",name:"Cameroon"},
        {code:"CAN",name:"Canada"},
        {code:"CPV",name:"Cape Verde"},
        {code:"CYM",name:"Cayman Islands"},
        {code:"CAF",name:"Central African Republic"},
        {code:"TCD",name:"Chad"},
        {code:"CHL",name:"Chile"},
        {code:"CHN",name:"China"},
        {code:"CXR",name:"Christmas Island"},
        {code:"CCK",name:"Cocos (Keeling) Islands"},
        {code:"COL",name:"Colombia"},
        {code:"COM",name:"Comoros"},
        {code:"COG",name:"Congo"},
        {code:"COD",name:"Congo, the Democratic Republic of the"},
        {code:"COK",name:"Cook Islands"},
        {code:"CRI",name:"Costa Rica"},
        {code:"CIV",name:"Côte d'Ivoire"},
        {code:"HRV",name:"Croatia"},
        {code:"CUB",name:"Cuba"},
        {code:"CUW",name:"Curaçao"},
        {code:"CYP",name:"Cyprus"},
        {code:"CZE",name:"Czech Republic"},
        {code:"DNK",name:"Denmark"},
        {code:"DJI",name:"Djibouti"},
        {code:"DMA",name:"Dominica"},
        {code:"DOM",name:"Dominican Republic"},
        {code:"ECU",name:"Ecuador"},
        {code:"EGY",name:"Egypt"},
        {code:"SLV",name:"El Salvador"},
        {code:"GNQ",name:"Equatorial Guinea"},
        {code:"ERI",name:"Eritrea"},
        {code:"EST",name:"Estonia"},
        {code:"ETH",name:"Ethiopia"},
        {code:"FLK",name:"Falkland Islands (Malvinas)"},
        {code:"FRO",name:"Faroe Islands"},
        {code:"FJI",name:"Fiji"},
        {code:"FIN",name:"Finland"},
        {code:"FRA",name:"France"},
        {code:"GUF",name:"French Guiana"},
        {code:"PYF",name:"French Polynesia"},
        {code:"ATF",name:"French Southern Territories"},
        {code:"GAB",name:"Gabon"},
        {code:"GMB",name:"Gambia"},
        {code:"GEO",name:"Georgia"},
        {code:"DEU",name:"Germany"},
        {code:"GHA",name:"Ghana"},
        {code:"GIB",name:"Gibraltar"},
        {code:"GRC",name:"Greece"},
        {code:"GRL",name:"Greenland"},
        {code:"GRD",name:"Grenada"},
        {code:"GLP",name:"Guadeloupe"},
        {code:"GUM",name:"Guam"},
        {code:"GTM",name:"Guatemala"},
        {code:"GGY",name:"Guernsey"},
        {code:"GIN",name:"Guinea"},
        {code:"GNB",name:"Guinea-Bissau"},
        {code:"GUY",name:"Guyana"},
        {code:"HTI",name:"Haiti"},
        {code:"HMD",name:"Heard Island and McDonald Islands"},
        {code:"VAT",name:"Holy See (Vatican City State)"},
        {code:"HND",name:"Honduras"},
        {code:"HKG",name:"Hong Kong"},
        {code:"HUN",name:"Hungary"},
        {code:"ISL",name:"Iceland"},
        {code:"IND",name:"India"},
        {code:"IDN",name:"Indonesia"},
        {code:"IRN",name:"Iran, Islamic Republic of"},
        {code:"IRQ",name:"Iraq"},
        {code:"IRL",name:"Ireland"},
        {code:"IMN",name:"Isle of Man"},
        {code:"ISR",name:"Israel"},
        {code:"ITA",name:"Italy"},
        {code:"JAM",name:"Jamaica"},
        {code:"JPN",name:"Japan"},
        {code:"JEY",name:"Jersey"},
        {code:"JOR",name:"Jordan"},
        {code:"KAZ",name:"Kazakhstan"},
        {code:"KEN",name:"Kenya"},
        {code:"KIR",name:"Kiribati"},
        {code:"PRK",name:"Korea, Democratic People's Republic of"},
        {code:"KOR",name:"Korea, Republic of"},
        {code:"KWT",name:"Kuwait"},
        {code:"KGZ",name:"Kyrgyzstan"},
        {code:"LAO",name:"Lao People's Democratic Republic"},
        {code:"LVA",name:"Latvia"},
        {code:"LBN",name:"Lebanon"},
        {code:"LSO",name:"Lesotho"},
        {code:"LBR",name:"Liberia"},
        {code:"LBY",name:"Libya"},
        {code:"LIE",name:"Liechtenstein"},
        {code:"LTU",name:"Lithuania"},
        {code:"LUX",name:"Luxembourg"},
        {code:"MAC",name:"Macao"},
        {code:"MKD",name:"Macedonia, the former Yugoslav Republic of"},
        {code:"MDG",name:"Madagascar"},
        {code:"MWI",name:"Malawi"},
        {code:"MYS",name:"Malaysia"},
        {code:"MDV",name:"Maldives"},
        {code:"MLI",name:"Mali"},
        {code:"MLT",name:"Malta"},
        {code:"MHL",name:"Marshall Islands"},
        {code:"MTQ",name:"Martinique"},
        {code:"MRT",name:"Mauritania"},
        {code:"MUS",name:"Mauritius"},
        {code:"MYT",name:"Mayotte"},
        {code:"MEX",name:"Mexico"},
        {code:"FSM",name:"Micronesia, Federated States of"},
        {code:"MDA",name:"Moldova, Republic of"},
        {code:"MCO",name:"Monaco"},
        {code:"MNG",name:"Mongolia"},
        {code:"MNE",name:"Montenegro"},
        {code:"MSR",name:"Montserrat"},
        {code:"MAR",name:"Morocco"},
        {code:"MOZ",name:"Mozambique"},
        {code:"MMR",name:"Myanmar"},
        {code:"NAM",name:"Namibia"},
        {code:"NRU",name:"Nauru"},
        {code:"NPL",name:"Nepal"},
        {code:"NLD",name:"Netherlands"},
        {code:"NCL",name:"New Caledonia"},
        {code:"NZL",name:"New Zealand"},
        {code:"NIC",name:"Nicaragua"},
        {code:"NER",name:"Niger"},
        {code:"NGA",name:"Nigeria"},
        {code:"NIU",name:"Niue"},
        {code:"NFK",name:"Norfolk Island"},
        {code:"MNP",name:"Northern Mariana Islands"},
        {code:"NOR",name:"Norway"},
        {code:"OMN",name:"Oman"},
        {code:"PAK",name:"Pakistan"},
        {code:"PLW",name:"Palau"},
        {code:"PSE",name:"Palestinian Territory, Occupied"},
        {code:"PAN",name:"Panama"},
        {code:"PNG",name:"Papua New Guinea"},
        {code:"PRY",name:"Paraguay"},
        {code:"PER",name:"Peru"},
        {code:"PHL",name:"Philippines"},
        {code:"PCN",name:"Pitcairn"},
        {code:"POL",name:"Poland"},
        {code:"PRT",name:"Portugal"},
        {code:"PRI",name:"Puerto Rico"},
        {code:"QAT",name:"Qatar"},
        {code:"REU",name:"Réunion"},
        {code:"ROU",name:"Romania"},
        {code:"RUS",name:"Russian Federation"},
        {code:"RWA",name:"Rwanda"},
        {code:"BLM",name:"Saint Barthélemy"},
        {code:"SHN",name:"Saint Helena, Ascension and Tristan da Cunha"},
        {code:"KNA",name:"Saint Kitts and Nevis"},
        {code:"LCA",name:"Saint Lucia"},
        {code:"MAF",name:"Saint Martin (French part)"},
        {code:"SPM",name:"Saint Pierre and Miquelon"},
        {code:"VCT",name:"Saint Vincent and the Grenadines"},
        {code:"WSM",name:"Samoa"},
        {code:"SMR",name:"San Marino"},
        {code:"STP",name:"Sao Tome and Principe"},
        {code:"SAU",name:"Saudi Arabia"},
        {code:"SEN",name:"Senegal"},
        {code:"SRB",name:"Serbia"},
        {code:"SYC",name:"Seychelles"},
        {code:"SLE",name:"Sierra Leone"},
        {code:"SGP",name:"Singapore"},
        {code:"SXM",name:"Sint Maarten (Dutch part)"},
        {code:"SVK",name:"Slovakia"},
        {code:"SVN",name:"Slovenia"},
        {code:"SLB",name:"Solomon Islands"},
        {code:"SOM",name:"Somalia"},
        {code:"ZAF",name:"South Africa"},
        {code:"SGS",name:"South Georgia and the South Sandwich Islands"},
        {code:"SSD",name:"South Sudan"},
        {code:"ESP",name:"Spain"},
        {code:"LKA",name:"Sri Lanka"},
        {code:"SDN",name:"Sudan"},
        {code:"SUR",name:"Suriname"},
        {code:"SJM",name:"Svalbard and Jan Mayen"},
        {code:"SWZ",name:"Swaziland"},
        {code:"SWE",name:"Sweden"},
        {code:"CHE",name:"Switzerland"},
        {code:"SYR",name:"Syrian Arab Republic"},
        {code:"TWN",name:"Taiwan, Province of China"},
        {code:"TJK",name:"Tajikistan"},
        {code:"TZA",name:"Tanzania, United Republic of"},
        {code:"THA",name:"Thailand"},
        {code:"TLS",name:"Timor-Leste"},
        {code:"TGO",name:"Togo"},
        {code:"TKL",name:"Tokelau"},
        {code:"TON",name:"Tonga"},
        {code:"TTO",name:"Trinidad and Tobago"},
        {code:"TUN",name:"Tunisia"},
        {code:"TUR",name:"Turkey"},
        {code:"TKM",name:"Turkmenistan"},
        {code:"TCA",name:"Turks and Caicos Islands"},
        {code:"TUV",name:"Tuvalu"},
        {code:"UGA",name:"Uganda"},
        {code:"UKR",name:"Ukraine"},
        {code:"ARE",name:"United Arab Emirates"},
        {code:"GBR",name:"United Kingdom"},
        {code:"USA",name:"United States"},
        {code:"UMI",name:"United States Minor Outlying Islands"},
        {code:"URY",name:"Uruguay"},
        {code:"UZB",name:"Uzbekistan"},
        {code:"VUT",name:"Vanuatu"},
        {code:"VEN",name:"Venezuela, Bolivarian Republic of"},
        {code:"VNM",name:"Viet Nam"},
        {code:"VGB",name:"Virgin Islands, British"},
        {code:"VIR",name:"Virgin Islands, U.S."},
        {code:"WLF",name:"Wallis and Futuna"},
        {code:"ESH",name:"Western Sahara"},
        {code:"YEM",name:"Yemen"},
        {code:"ZMB",name:"Zambia"},
        {code:"ZWE",name:"Zimbabwe"},
      ],
      currentImageIndex: 0,
      sliderStyle: {},
      quoteForm:{},
      errors:{}
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeImage = this.changeImage.bind(this);
    
    setInterval(this.changeImage, 3000);
  }

  changeImage() {
    // console.log("This has been called");
    let i = this.state.currentImageIndex;
    this.setState({
      sliderStyle: {
        ...this.state.sliderStyle,
        backgroundImage: `url(${this.state.images[i]})`,
      },
    });

    if (i < this.state.images.length - 1) {
      i++;
      this.setState({ currentImageIndex: i });
    } else {
      i = 0;
      this.setState({ currentImageIndex: i });
    }
  }

  componentDidMount() {
    this.setState({
      sliderStyle: {
        backgroundImage: `url(${this.state.images[0]})`,
        backgroundSize: "cover",
      },
    });
  }

  
  async handleSubmit(event) {
    event.preventDefault();
    console.log(`Event is called here`);

    const result = await trackPackage(this.state.value);
    console.log(result);

    if (result.success) {
      this.props.history.push("/tracking/" + this.state.value);
    } else {
      swal({
        type: "error",
        title: "ERROR",
        text: "Invalid Tracking ID",
        // icon: "warning",
      });
    }
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }
  
  renderOptionsLists = (list) => {
    return list.map(i => <option key={i} value={i}>{i}</option>)
  }

  renderCountriesOptionList = (list) => {
    return list.map(i => <option key={i.code} value={i.code}>{i.name}</option>)
  }

  getQuoteInternational = () =>
  {
    console.log(`Get Quote Event is called here`,this.state.quoteForm);
    var quoteForm = this.state.quoteForm;
    var errors = {};
    var errorFound = false
    if(!quoteForm.senderDistrict)
    {
      errorFound = true;
      errors.senderDistrict = "Please select the origin"
    }

    if(!quoteForm.destinationCountry )
    {
      errorFound = true;
      errors.destinationCountry = "Please select the destination"
    }

    if(!quoteForm.weight || (quoteForm.weight && quoteForm.weight<=0))
    {
      errorFound = true;
      errors.weight = "Please enter a valid weight"
    }

    if(!quoteForm.parcelType){
      errorFound = true;
      errors.parcelType = "Please select a parcel type"
    }

    if(!quoteForm.pickupType){
      errorFound = true;
      errors.pickupType = "Please select an option"
    }

    if (errorFound) {
      this.setState({errors:errors})
      return;
    }
    var quoteForm = this.state.quoteForm
    this.props.history.push(`/booking?sd=${quoteForm.senderDistrict}&dcc=${quoteForm.destinationCountryCode}&dcn=${quoteForm.destinationCountry}&w=${quoteForm.weight}&pat=${quoteForm.parcelType}&pit=${quoteForm.pickupType}&st=International`)
  }

  getQuoteDomestic = () => {
    console.log(`Get Quote Event is called here`,this.state.quoteForm);
    var quoteForm = this.state.quoteForm;
    var errors = {};
    var errorFound = false
    if(!quoteForm.senderDistrict)
    {
      errorFound = true;
      errors.senderDistrict = "Please select the origin"
    }

    if(!quoteForm.destinationCountry )
    {
      errorFound = true;
      errors.destinationCountry = "Please select the destination"
    }

    if(!quoteForm.weight || (quoteForm.weight && quoteForm.weight<=0))
    {
      errorFound = true;
      errors.weight = "Please enter a valid weight"
    }

    if(!quoteForm.parcelType){
      errorFound = true;
      errors.parcelType = "Please select a parcel type"
    }

    if(!quoteForm.pickupType){
      errorFound = true;
      errors.pickupType = "Please select an option"
    }

    if (errorFound) {
      this.setState({errors:errors})
      return;
    }
    var quoteForm = this.state.quoteForm
    this.props.history.push(`/booking?sd=${quoteForm.senderDistrict}&dcc=${quoteForm.destinationCountryCode}&dcn=${quoteForm.destinationCountry}&w=${quoteForm.weight}&pat=${quoteForm.parcelType}&pit=${quoteForm.pickupType}&st=Domestic`)
  }

  handleDistrictChange = (e) => {

    // Get selected district
    let idx = e.target.selectedIndex;

    let district = '';
    if(idx > 0)
    {
      district = this.state.districts[idx - 1]
    }
    

    // Update update the quote form
    let quoteFormNew = {...this.state.quoteForm};
    quoteFormNew.senderDistrict = district

    this.state.errors.senderDistrict = null
    // Update the state
    this.setState( {quoteForm:quoteFormNew})
  }

  handleDestinationCountryChange = (e) =>
  {
    // Get selected district
    let idx = e.target.selectedIndex;
    let country = {}

    if(idx > 0)
    {
      country = this.state.countries[idx - 1]
    }
    console.log(country)

    // Update update the quote form
    let quoteFormNew = {...this.state.quoteForm};
    quoteFormNew.destinationCountryCode = country.code || ''
    quoteFormNew.destinationCountry = country.name || ''
    this.state.errors.destinationCountry = null
    // Update the state
    this.setState( {quoteForm:quoteFormNew})
  }

  weightChanged = (e) => {
    let newQuoteForm = {...this.state.quoteForm};
    newQuoteForm.weight = e.target.value
    if(newQuoteForm.weight >0){
      
    this.state.errors.weight = null
    }else{
      this.state.errors.weight = "Please enter a valid weight"
    }
    this.setState({quoteForm:newQuoteForm});
  }

  handleParcelTypeChanged = (e) => {
    let newQuoteForm = {...this.state.quoteForm};
    newQuoteForm.parcelType = e.target.id

    this.state.errors.parcelType = null
    this.setState({quoteForm:newQuoteForm});
  }

  handlePickupTypeChanged = (e) => {
    let newQuoteForm = {...this.state.quoteForm};
    newQuoteForm.pickupType = e.target.id

    this.state.errors.pickupType = null
    this.setState({quoteForm:newQuoteForm});
  }

  handleDomesticOriginChange = (e) => {

    // Get selected district
    let idx = e.target.selectedIndex;
    let district = ''
    if(idx > 0)
    {
      district = this.state.domesticAreas[idx- 1]
    }

    let newQuoteForm = {...this.state.quoteForm}
    newQuoteForm.senderDistrict = district;

    this.setState({quoteForm:newQuoteForm})
  }

  handleDomesticDestinationChange = (e) => {

    // Get selected district
    let idx = e.target.selectedIndex;
    let district = ''
    if(idx > 0)
    {
      district = this.state.domesticAreas[idx- 1]
    }

    let newQuoteForm = {...this.state.quoteForm}
    newQuoteForm.destinationCountry = district;

    this.setState({quoteForm:newQuoteForm})
  }

  resetState = () => {
    console.log(this.state.quoteForm)
    var quoteForm ={};

    this.setState({quoteForm:quoteForm});
  }
  render() {
    return (
      <div className="wrapper">
        <Header />

        {/* banner section */}

        <section
          style={this.state.sliderStyle}
          className="mask-overlay white-clr pt-10"
        >
          {/* carousel */}
          <div
            style={{ zIndex: "1" }}
            className="carousel-fade slide"
            id="carouselImg"
          >
            {/* content on the carousel */}

            <div className="container theme-container rel-div">
              <div className="container-fluid d-flex justify-content-center">
                <div className="row" style={{marginBottom:'40px',marginTop:'40px'}}>
                <div className="col-xl-6 col-lg-6 tracking-form text-center">
                  <div style={{paddingTop:'110px'}}></div>
                  <div>
                    <h2 className="title-1 pb-5">
                      {" "}
                      SRI LANKAN WORLDWIDE DELIVERY COMPANY{" "}
                    </h2>
                  </div>

                  {/* tracking form */}

                  <div className="row" >
                    <form
                      className="container-fluid"
                      onSubmit={this.handleSubmit}
                    >
                      <div className="row" >
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div className="form-group">
                            <input
                              style={{ color: "black", fontSize: "18px" }}
                              type="text"
                              className="form-control box-shadow"
                              value={this.state.value}
                              onChange={this.handleChange}
                              required
                              placeholder="Enter your product ID"
                            />
                          </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div className="form-group">
                            <button
                              style={{ fontSize: "15px", color: "white" }}
                              className="btn-1"
                            >
                              track your parcel
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="pad-50"></div>
                    </form>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6" >
                  <div style={{maxWidth:'400px',marginLeft:'auto', marginRight:'auto'}}>
                    <Tabs 
                      transition={false} 
                      style={{backgroundColor:"gray",color:"black"}} 
                      onClick={this.resetState}
                    >
                      <Tab 
                        eventKey="international" 
                        title="International" 
                        style={{backgroundColor:"white",color:"black"}} 
                      >
                        <div style={{padding:'10px 20px'}}>
                          <Form onSubmit={e => e.preventDefault()}>
                            <Form.Group>
                              <Form.Label>Sending from - District</Form.Label>
                              <Form.Control 
                              as="select" 
                              onChange={this.handleDistrictChange}
                              value = {this.state.quoteForm.senderDistrict || ''}
                              >
                                <option>Select District</option>
                                {this.renderOptionsLists(this.state.districts)}
                              </Form.Control>
                              <span style={{color:'red'}} id='origin-district-error'>
                                {this.state.errors.senderDistrict || ''}
                              </span>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Sending to - Country</Form.Label>
                              <Form.Control 
                                as="select" 
                                onChange={this.handleDestinationCountryChange}
                                value = {this.state.quoteForm.destinationCountryCode || ''}
                              >
                                <option>Select Country</option>
                                {this.renderCountriesOptionList(this.state.countries)}
                              </Form.Control>
                              <span style={{color:'red'}} id='destination-country-error'> {this.state.errors.destinationCountry || ''}</span>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Weight</Form.Label>
                              <Form.Control 
                                type="number" 
                                placeholder="Enter the weight" 
                                onChange={this.weightChanged}
                                value = {this.state.quoteForm.weight || ''}
                              />
                              <span style={{color:'red'}} id='weight-error'>{this.state.errors.weight || ''}</span>
                            </Form.Group>
                            <div >
                              <div className='row' style={{marginTop:'25px'}}>
                                <div className='col'>
                                  <Form.Label>Parcel Type</Form.Label>
                                </div>
                                <div className='col-sm-4' style={{textAlign:'center'}}>
                                  <Form.Check 
                                    inline 
                                    type='radio' 
                                    name="groupOptions" 
                                    checked={this.state.quoteForm.parcelType === 'Package'}
                                    label="Package" 
                                    id="Package" 
                                    onChange={this.handleParcelTypeChanged}
                                  />
                                </div>
                                <div 
                                  className='col-sm-4' 
                                  style={{textAlign:'center'}}
                                >
                                  <Form.Check 
                                    inline 
                                    type='radio' 
                                    name="groupOptions" 
                                    checked={this.state.quoteForm.parcelType === 'Document'} 
                                    label="Document"
                                    id="Document" 
                                    onChange={this.handleParcelTypeChanged}
                                  />
                                </div>
                              </div>
                              <span style={{color:'red'}} id='parcel-type-error'>{this.state.errors.parcelType || ''}</span>
                            </div>
                            <div>
                              <div className='row' style={{marginTop:'25px'}}>
                                <div className='col' style={{textAlign:'center'}}>
                                  <Form.Check 
                                    inline 
                                    type='radio' 
                                    checked={this.state.quoteForm.pickupType === 'Pickup'} 
                                    name="groupOptions1" 
                                    label="Pickup" 
                                    id="Pickup" 
                                    onChange={this.handlePickupTypeChanged}
                                  />
                                </div>
                                <div className='col' style={{textAlign:'center'}}>
                                  <Form.Check 
                                    inline 
                                    type='radio' 
                                    name="groupOptions1" 
                                    checked={this.state.quoteForm.pickupType === 'DropOff'} 
                                    label="Drop Off"
                                    id="DropOff" 
                                    onChange={this.handlePickupTypeChanged}
                                  />
                                </div>
                                
                              </div>
                              <span style={{color:'red'}} id='pickup-type-error'>{this.state.errors.pickupType || ''}</span>
                            </div>
                            <div className="form-group" style={{padding:'10px 0px',textAlign:'center', marginTop:'40px'}}>
                              <button
                                style={{ fontSize: "15px", color: "white" ,width:'200px'}}
                                className="btn-1"
                                onClick={this.getQuoteInternational}
                              >
                                Get Quote
                              </button>
                            </div>
                          </Form>
                        </div>
                      </Tab>
                      <Tab eventKey="domestic" title="Domestic" style={{backgroundColor:"white",color:"black"}}>
                        <div style={{padding:'10px 20px'}}>
                          <Form onSubmit={e => e.preventDefault()}>
                            <Form.Group>
                              <Form.Label>Sending from - District</Form.Label>
                              <Form.Control as="select" onChange={this.handleDomesticOriginChange}>
                                <option >Select District</option>
                                {this.renderOptionsLists(this.state.domesticAreas)}
                              </Form.Control>
                              <span style={{color:'red'}} id='origin-district-error'>{this.state.errors.senderDistrict || ''}</span>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Sending to - District</Form.Label>
                              <Form.Control as="select" onChange={this.handleDomesticDestinationChange}>
                                <option>Select District</option>
                                {this.renderOptionsLists(this.state.domesticAreas)}
                              </Form.Control>
                              <span style={{color:'red'}} id='destination-country-error'> {this.state.errors.destinationCountry || ''}</span>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Weight</Form.Label>
                              <Form.Control type="number" placeholder="Enter the weight" onChange={this.weightChanged}/>
                              <span style={{color:'red'}} id='weight-error'>{this.state.errors.weight || ''}</span>
                            </Form.Group>
                            <div >
                              <div className='row' style={{marginTop:'25px'}}>
                                <div className='col'>
                                  <Form.Label>Parcel Type</Form.Label>
                                </div>
                                <div className='col-sm-4' style={{textAlign:'center'}}>
                                  <Form.Check 
                                    inline 
                                    type='radio' 
                                    name="groupOptions" 
                                    label="Package" 
                                    id="Package" 
                                    onChange={this.handleParcelTypeChanged}
                                    checked={this.state.quoteForm.parcelType === 'Package'}
                                  />
                                </div>
                                <div className='col-sm-4' style={{textAlign:'center'}}>
                                  <Form.Check 
                                    inline 
                                    type='radio' 
                                    name="groupOptions" 
                                    label="Document"
                                    id="Document"
                                    onChange={this.handleParcelTypeChanged}
                                    checked={this.state.quoteForm.parcelType === 'Document'}
                                  />
                                </div>
                              </div>
                              <span style={{color:'red'}} id='parcel-type-error'>{this.state.errors.parcelType || ''}</span>
                            </div>
                            <div>
                              <div className='row' style={{marginTop:'25px'}}>
                                <div className='col' style={{textAlign:'center'}}>
                                  <Form.Check 
                                    inline 
                                    type='radio' 
                                    name="groupOptions1" 
                                    label="Pickup" 
                                    id="Pickup" 
                                    onChange={this.handlePickupTypeChanged}
                                    checked={this.state.quoteForm.pickupType === 'Pickup'} 
                                  />
                                </div>
                                <div className='col' style={{textAlign:'center'}}>
                                  <Form.Check 
                                    inline 
                                    type='radio' 
                                    name="groupOptions1" 
                                    label="Drop Off"
                                    id="DropOff"
                                    onChange={this.handlePickupTypeChanged}
                                    checked={this.state.quoteForm.pickupType === 'DropOff'} 
                                  />
                                </div>
                                
                              </div>
                              <span style={{color:'red'}} id='pickup-type-error'>{this.state.errors.pickupType || ''}</span>
                            </div>
                            <div className="form-group" style={{padding:'10px 0px',textAlign:'center', marginTop:'40px'}}>
                              <button
                                style={{ fontSize: "15px", color: "white" ,width:'200px'}}
                                className="btn-1"
                                onClick={this.getQuoteDomestic}
                              >
                                Get Quote
                              </button>
                            </div>
                          </Form>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              
                </div>
                </div>
            </div>
          </div>
        </section>

        {/* services section */}

        <section className="pad-50 feature feature-2">
          <div className="theme-container container">
            <div className="row">
              <br />
              <div
                className="justify-content-center col-md-12 col-lg-4 pt-3"
                style={{ textAlign: "justify" }}
              >
                <div
                  className="feature-content wow rotateInDownRight"
                  data-wow-offset="50"
                  data-wow-delay=".30s"
                >
                  <br />
                  <img
                    src="assets\img\services\homepageServices\icon-3.png"
                    className=""
                    alt="..."
                  />
                  <h2 className="title-1">Land Delivery</h2>
                  <p>
                    Regardless of the size of your package we will provide a
                    cheap and reliable priced solution. Our land freight is
                    catered towards ....
                  </p>
                </div>
              </div>

              <div
                className="justify-content-center col-md-12 col-lg-4 pt-3"
                style={{ textAlign: "justify" }}
              >
                <div
                  className="feature-content wow rotateInDownRight"
                  data-wow-offset="50"
                  data-wow-delay=".30s"
                >
                  <img
                    src="assets\img\services\homepageServices\icon-2.png"
                    className=""
                    alt="..."
                  />
                  <h2 className="title-1">Air Delivery</h2>
                  <p>
                    Air freight is the mode which uses the best delivery speeds
                    depending on your choice and requirements. With the help of
                    our ...
                  </p>
                </div>
              </div>

              <div
                className="justify-content-center col-md-12 col-lg-4 pt-3"
                style={{ textAlign: "justify" }}
              >
                <div
                  className="feature-content wow rotateInDownRight"
                  data-wow-offset="50"
                  data-wow-delay=".30s"
                >
                  <img
                    src="assets\img\services\homepageServices\icon-1.png"
                    className=""
                    alt="..."
                  />
                  <h2 className="title-1">Sea Delivery</h2>
                  <p>
                    The most reasonable and cost-efficient freight choice is
                    this. Our wide range of Ocean freight Service working
                    together with ...
                  </p>
                </div>
              </div>
            </div>
          </div>
          <br />

          <div className="container">
            <div className="row"></div>
          </div>
        </section>
        <br />

        <br />

        <Footer />
      </div>
    );
  }
}
export default Home;

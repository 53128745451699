import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { parse } from "query-string";
import React from "react";
import { Form, Modal } from "react-bootstrap";
import { Media } from "reactstrap";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import OrderConfirm from "../Components/PDFDocuments/OrderConfirm";
import {
  countries,
  createShipment,
  getRates,
  phoneTypes,
} from "../Services/tracking.service";

class BookingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shipmentDetails: {},
      consigneeDetails: {},
      consignorDetails: {},
      currentDetails: {},
      rateDetails: [],
      sampleRateDetails: [
        {
          image: "",
          serviceName: "",
          description: "",
          pickupCost: 0,
          totalCost: 0,
          id: "",
        },
      ],
      quoteData: {
        paymentType: "cash",
      },
      packages: [{}],
      declarations: [{}],
      declarationValue: 0,
      declarationWeight: 0,
      declarationBags: 0,
      // selectedRate :null,// When releasing
      selectedRate: {},
      error: {},
      currentSection: "",
      validated: false,
      tandcModalOpen: false,
    };

    this.getImageUrl = this.getImageUrl.bind(this);
    this.setRate = this.setRate.bind(this);
  }

  // Page order
  // ratesList => senderDetails => packageDetails => itemDeclaration

  async componentDidMount() {
    // TODO - set the state for quoteData
    var search = this.props.location.search;

    const { sd, dcc, dcn, w, pat, pit, st } = parse(search);
    console.log(sd, dcc, dcn, w, pat, pit, st);
    console.log("mounted");

    let quoteData = { ...this.state.quoteData };

    quoteData.shipmentType = st;
    quoteData.senderDistrict = sd;
    quoteData.sendArea = dcn;
    quoteData.sendCountryCode = dcc;
    quoteData.parcelType = pat;
    quoteData.pickupType = pit;
    quoteData.weight = w;

    if (!st || !sd || !dcn || !pat || !pit || !w) {
      this.props.history.push("/");
      return;
    }

    // let quoteData=this.state.quoteData;

    var ratesRequest = {
      isWebBooking: true,
      parcelType: quoteData.parcelType,
      shipmentType: quoteData.shipmentType,
      shipmentParcelWeight: quoteData.weight,
      shipmentDeliverTo: quoteData.sendArea,
      shipmentCollectFrom: quoteData.senderDistrict,
      pickupType: quoteData.pickupType,
    };

    var preConsignor = {
      countryCode: "LKA",
      city: quoteData.senderDistrict,
    };

    var preConsignorCountry = countries.find(
      (c) => c.code == preConsignor.countryCode
    );
    if (preConsignorCountry) {
      preConsignor.country = preConsignorCountry.name;
    }

    var preConsignee = {};

    if (quoteData.shipmentType === "International") {
      preConsignee.countryCode = quoteData.sendCountryCode;
    } else {
      preConsignee.countryCode = "LKA";
      preConsignee.city = quoteData.sendArea;
    }
    // currentDetailNew.countryCode = country.code;
    // currentDetailNew.country = country.name
    var preConsigneeCountry = countries.find(
      (c) => c.code == preConsignee.countryCode
    );
    if (preConsigneeCountry) {
      preConsignee.country = preConsigneeCountry.name;
    }

    var ratesResponse = await getRates(ratesRequest);
    if (!ratesResponse.success) {
      this.setState({ error: "Fetching rates failed" });
      return;
    }

    this.setState({
      rateDetails: ratesResponse.data,
      currentSection: "ratesList",
      quoteData: quoteData,
      consignorDetails: preConsignor,
      consigneeDetails: preConsignee,
    });
  }

  handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({ validated: true });
    event.preventDefault();
    event.stopPropagation();
  };

  shippingServiceCard(shippingData, i) {
    var price = parseFloat(shippingData.priceRange);
    return (
      <div
        className="row card-body"
        style={{
          textAlign: "center",
          background: "#f9f9f9",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          borderRadius: "5px",
          margin: "35px 10px",
        }}
        key={shippingData.id}
      >
        <div className="col">
          <img
            style={{ maxWidth: "200px", maxHeight: "100px" }}
            src={this.getImageUrl(shippingData.service)}
          />
        </div>
        <div className="col">
          <h2>{shippingData.service || `Local Service ${i + 1}`}</h2>
          <p>{shippingData.transIt}</p>
        </div>
        <div className="col">
          {shippingData.pickupCost ? (
            <p>Pickup cost: {shippingData.pickupCost.toLocaleString()} LKR</p>
          ) : (
            <p></p>
          )}
        </div>
        <div className="col">
          <h4 style={{ color: "#8E211A" }}>{price.toLocaleString()} LKR</h4>
          <button
            style={{
              fontSize: "12px",
              color: "white",
              width: "150px",
              height: "45px",
            }}
            className="btn-1"
            onClick={this.setRate}
            id={shippingData.id}
          >
            Book Service
          </button>
        </div>
      </div>
    );
  }

  setRate = (e) => {
    console.log(e.target.id);
    var rate = this.state.rateDetails.find((r) => r.id === e.target.id);
    console.log(rate);
    this.setState({
      selectedRate: rate,
      currentSection: "senderDetails",
      currentDetails: this.state.consignorDetails,
    });
  };

  addPackage = () => {
    let newPackages = [...this.state.packages, {}];
    this.setState({ packages: newPackages });
  };

  getImageUrl = (service) => {
    var url = "";
    if (service === "PLK") {
      url = "/assets/img/logo/parcel.png";
    }
    if (service === "ARAMEX") {
      url = "/assets/img/logo/aramex.png";
    }
    if (service === "DHL") {
      url = "/assets/img/logo/dhl.png";
    }
    if (service === "UPS") {
      url = "/assets/img/logo/UPS.png";
    }
    if (service === "SKYNET") {
      url = "/assets/img/logo/skynet.png";
    }
    return url;
  };

  deletePackage = (e) => {
    var index = e.target.getAttribute("data-index");
    console.log(index);
    var packagesNew = this.state.packages;
    packagesNew.splice(index, 1);
    this.setState({ packages: packagesNew });
  };

  packageNameChanged = (e) => {
    var index = e.target.getAttribute("data-index");
    var packageItem = this.state.packages[index];

    packageItem.name = e.target.value;
    var packagesNew = this.state.packages;

    packagesNew.splice(index, 1, packageItem);

    this.setState({ packages: packagesNew });
  };

  packageQuantityChanged = (e) => {
    var index = e.target.getAttribute("data-index");
    var packageItem = this.state.packages[index];

    packageItem.quantity = e.target.value;
    var packagesNew = this.state.packages;

    packagesNew.splice(index, 1, packageItem);

    this.setState({ packages: packagesNew });
  };

  packageWeightChanged = (e) => {
    var index = e.target.getAttribute("data-index");
    var packageItem = this.state.packages[index];

    packageItem.weight = e.target.value;
    var packagesNew = this.state.packages;

    packagesNew.splice(index, 1, packageItem);

    this.setState({ packages: packagesNew });
  };

  packageXChanged = (e) => {
    var index = e.target.getAttribute("data-index");
    var packageItem = this.state.packages[index];

    packageItem.x = e.target.value;
    var packagesNew = this.state.packages;

    packagesNew.splice(index, 1, packageItem);

    this.setState({ packages: packagesNew });
  };

  packageYChanged = (e) => {
    var index = e.target.getAttribute("data-index");
    var packageItem = this.state.packages[index];

    packageItem.y = e.target.value;
    var packagesNew = this.state.packages;

    packagesNew.splice(index, 1, packageItem);

    this.setState({ packages: packagesNew });
  };

  packageZChanged = (e) => {
    var index = e.target.getAttribute("data-index");
    var packageItem = this.state.packages[index];

    packageItem.z = e.target.value;
    var packagesNew = this.state.packages;

    packagesNew.splice(index, 1, packageItem);

    this.setState({ packages: packagesNew });
  };

  packagesCard = (packageDetails, i) => {
    console.log(packageDetails);
    return (
      <div className="row">
        <div className="col-3">
          <Form.Group>
            <Form.Control
              type="text"
              value={packageDetails.name || ""}
              onChange={this.packageNameChanged}
              data-index={i}
            />
          </Form.Group>
        </div>
        <div className="col-2">
          <Form.Group>
            <Form.Control
              type="number"
              value={packageDetails.quantity || 0}
              onChange={this.packageQuantityChanged}
              data-index={i}
              min="0"
            />
          </Form.Group>
        </div>
        <div className="col-2">
          <Form.Group>
            <Form.Control
              type="number"
              value={packageDetails.weight || 0}
              onChange={this.packageWeightChanged}
              data-index={i}
              min="0"
            />
          </Form.Group>
        </div>
        <div className="col-4">
          <Form.Group>
            <div className="row">
              <div className="col">
                <Form.Control
                  type="number"
                  value={packageDetails.x || 0}
                  onChange={this.packageXChanged}
                  data-index={i}
                  min="0"
                />
              </div>
              <div
                className="col col-auto"
                style={{ paddingRight: "0", paddingLeft: "0" }}
              >
                X
              </div>
              <div className="col">
                <Form.Control
                  type="number"
                  value={packageDetails.y || 0}
                  onChange={this.packageYChanged}
                  data-index={i}
                  min="0"
                />
              </div>
              <div
                className="col col-auto "
                style={{ paddingRight: "0", paddingLeft: "0" }}
              >
                X
              </div>
              <div className="col ">
                <Form.Control
                  type="number"
                  value={packageDetails.z || 0}
                  onChange={this.packageZChanged}
                  data-index={i}
                  min="0"
                />
              </div>
            </div>
          </Form.Group>
        </div>
        <div className="col-1">
          <div className="form-group" style={{ textAlign: "center" }}>
            <button
              style={{
                fontSize: "12px",
                color: "white",
                width: "50px",
                float: "right",
                padding: "5px 0",
              }}
              className="btn-1"
              onClick={this.deletePackage}
              data-index={i}
            >
              X
            </button>
          </div>
        </div>
        <hr
          style={{
            border: "1px black",
            height: "1px",
            width: "100%",
            margin: "0px 15px 10px 15px",
            color: "black",
            background: "#aeaeae54",
          }}
        />
      </div>
    );
  };

  renderCountriesOptionList = (list) => {
    return list.map((i) => (
      <option key={i.code} value={i.code}>
        {i.name}
      </option>
    ));
  };

  currentDetailsFirstNameChange = (e) => {
    let currentDetailNew = { ...this.state.currentDetails };
    currentDetailNew.firstName = e.target.value;

    this.setState({ currentDetails: currentDetailNew });
  };

  currentDetailsLastNameChange = (e) => {
    let currentDetailNew = { ...this.state.currentDetails };
    currentDetailNew.lastName = e.target.value;

    this.setState({ currentDetails: currentDetailNew });
  };

  currentDetailsNicChange = (e) => {
    let currentDetailNew = { ...this.state.currentDetails };
    currentDetailNew.nic = e.target.value;

    this.setState({ currentDetails: currentDetailNew });
  };

  currentDetailsCompanyChange = (e) => {
    let currentDetailNew = { ...this.state.currentDetails };
    currentDetailNew.company = e.target.value;

    this.setState({ currentDetails: currentDetailNew });
  };

  currentDetailsAddressChange = (e) => {
    let currentDetailNew = { ...this.state.currentDetails };
    currentDetailNew.address = e.target.value;

    this.setState({ currentDetails: currentDetailNew });
  };

  currentDetailsEmailChange = (e) => {
    let currentDetailNew = { ...this.state.currentDetails };
    currentDetailNew.email = e.target.value;

    this.setState({ currentDetails: currentDetailNew });
  };

  currentDetailsAddress2Change = (e) => {
    let currentDetailNew = { ...this.state.currentDetails };
    currentDetailNew.address2 = e.target.value;

    this.setState({ currentDetails: currentDetailNew });
  };

  currentDetailsAddress3Change = (e) => {
    let currentDetailNew = { ...this.state.currentDetails };
    currentDetailNew.address3 = e.target.value;

    this.setState({ currentDetails: currentDetailNew });
  };

  currentDetailsZipCodeChange = (e) => {
    let currentDetailNew = { ...this.state.currentDetails };
    currentDetailNew.zipCode = e.target.value;

    this.setState({ currentDetails: currentDetailNew });
  };

  currentDetailsCityChange = (e) => {
    let currentDetailNew = { ...this.state.currentDetails };
    currentDetailNew.city = e.target.value;

    this.setState({ currentDetails: currentDetailNew });
  };

  currentDetailsStateChange = (e) => {
    let currentDetailNew = { ...this.state.currentDetails };
    currentDetailNew.state = e.target.value;

    this.setState({ currentDetails: currentDetailNew });
  };

  currentDetailsMobileNumber1Change = (e) => {
    let currentDetailNew = { ...this.state.currentDetails };
    currentDetailNew.mobileNumber1 = e.target.value;

    this.setState({ currentDetails: currentDetailNew });
  };

  currentDetailsMobileNumber2Change = (e) => {
    let currentDetailNew = { ...this.state.currentDetails };
    currentDetailNew.mobileNumber2 = e.target.value;

    this.setState({ currentDetails: currentDetailNew });
  };

  currentDetailsLandlineChange = (e) => {
    let currentDetailNew = { ...this.state.currentDetails };
    currentDetailNew.landline = e.target.value;

    this.setState({ currentDetails: currentDetailNew });
  };


  currentDetailsCountryChange = (e) => {
    let currentDetailNew = { ...this.state.currentDetails };
    let idx = e.target.selectedIndex;
    let country = countries[idx];

    currentDetailNew.countryCode = country.code;
    currentDetailNew.country = country.name;
    this.setState({ currentDetails: currentDetailNew });
  };

  phoneTypesOptionsList(list) {
    return list.map((i) => (
      <option key={i.value} value={i.value}>
        {i.display}
      </option>
    ));
  }

  detailsSubmit = (e) => {
    console.log(this.state);
    let modifiedState = {};
    setTimeout(() => {
      if (this.state.validated) {
        if (this.state.currentSection === "senderDetails") {
          modifiedState.validated = false;
          modifiedState.consignorDetails = this.state.currentDetails;
          modifiedState.currentSection = "receiverDetails";
          modifiedState.currentDetails = this.state.consigneeDetails;
        }
        if (this.state.currentSection === "receiverDetails") {
          modifiedState.validated = false;
          modifiedState.consigneeDetails = this.state.currentDetails;
          modifiedState.currentSection = "itemDeclaration";
          modifiedState.currentDetails = {};
        }
        if (this.state.currentSection === "itemDeclaration") {
          modifiedState.validated = false;
          modifiedState.currentSection = "packageDetails";
          modifiedState.currentDetails = {};
        }
        if (this.state.currentSection === "packageDetails") {
          modifiedState.validated = false;
          modifiedState.currentSection = "orderSummary";
          modifiedState.currentDetails = {};
        }

        this.setState(modifiedState);
        console.log("new state", this.state);
      }
    }, 50);
  };

  goBack = (e) => {
    console.log("old state", this.state);
    let modifiedState = {};
    if (this.state.currentSection === "senderDetails") {
      modifiedState.consignorDetails = this.state.currentDetails;
      modifiedState.currentSection = "ratesList";
    }
    if (this.state.currentSection === "receiverDetails") {
      modifiedState.consigneeDetails = this.state.currentDetails;
      modifiedState.currentSection = "senderDetails";
      modifiedState.currentDetails = this.state.consignorDetails;
    }
    if (this.state.currentSection === "itemDeclaration") {
      modifiedState.currentSection = "receiverDetails";
      modifiedState.currentDetails = this.state.consigneeDetails;
    }
    if (this.state.currentSection === "packageDetails") {
      modifiedState.currentSection = "itemDeclaration";
      modifiedState.currentDetails = {};
    }

    modifiedState.validated = false;

    this.setState(modifiedState);
    // console.log("new state", this.state);
  };

  getPrice = () => {
    if (this.state.selectedRate && this.state.selectedRate.priceRange) {
      var price = parseFloat(this.state.selectedRate.priceRange);
      return parseFloat(price.toFixed(2));
    }

    return 100;
  };

  getPickupCost = () => {
    if (this.state.selectedRate && this.state.selectedRate.pickupCost) {
      var price = parseFloat(this.state.selectedRate.pickupCost);
      return parseFloat(price.toFixed(2));
    }

    return 0;
  };

  getTotalPrice = () => {
    var price = this.getPrice();
    if (this.state.quoteData.pickupType === "Pickup") {
      var pickupCost = this.getPickupCost();
      var total = price + pickupCost;

      return parseFloat(total.toFixed(2));
    }

    return price;
  };

  declarationCard = (declarationDetails, i) => {
    return (
      <div className="row" key={i}>
        <div className="col">
          <Form.Group>
            <Form.Control
              type="text"
              value={declarationDetails.name || ""}
              onChange={this.declarationNameChanged}
              data-index={i}
            />
          </Form.Group>
        </div>
        <div className="col-2">
          <Form.Group>
            <Form.Control
              type="number"
              value={declarationDetails.quantity || 0}
              onChange={this.declarationQuantityChanged}
              data-index={i}
              min="0"
            />
          </Form.Group>
        </div>
        <div className="col-2">
          <Form.Group>
            <Form.Control
              type="number"
              value={declarationDetails.unitValue || 0}
              onChange={this.declarationUnitValueChanged}
              data-index={i}
              min="0"
            />
          </Form.Group>
        </div>
        <div className="col-2">
          <Form.Group>
            <Form.Control
              type="number"
              value={declarationDetails.totalValue || 0}
              disabled
              min="0"
            />
          </Form.Group>
        </div>
        <div className="col-1">
          <div className="form-group" style={{ textAlign: "center" }}>
            <button
              style={{
                fontSize: "12px",
                color: "white",
                width: "50px",
                float: "right",
                padding: "5px 0",
              }}
              className="btn-1"
              onClick={this.deleteDeclaration}
              data-index={i}
            >
              X
            </button>
          </div>
        </div>
        <hr
          style={{
            border: "1px black",
            height: "1px",
            width: "100%",
            margin: "0px 15px 10px 15px",
            color: "black",
            background: "#aeaeae54",
          }}
        />
      </div>
    );
  };

  addDeclaration = () => {
    let newDeclarations = [...this.state.declarations, {}];
    this.setState({ declarations: newDeclarations });
  };

  updateDeclarationTotalValue = () => {
    var total = this.state.declarations.reduce((result, item) => {
      if (item && item.totalValue) {
        result = result + item.totalValue;
      }

      return result;
    }, 0);
    console.log("dc value", total);
    this.setState({ declarationValue: total });
  };

  declarationWeightChanged = (e) => {
    let newWeight = parseFloat(e.target.value);
    this.setState({ declarationWeight: newWeight });
  };

  declarationBagsChanged = (e) => {
    let newBags = parseFloat(e.target.value);
    this.setState({ declarationBags: newBags });
  };

  deleteDeclaration = (e) => {
    var index = e.target.getAttribute("data-index");
    console.log(index);
    var newDeclarations = this.state.declarations;
    newDeclarations.splice(index, 1);
    this.setState({ declarations: newDeclarations });
    this.updateDeclarationTotalValue();
  };

  declarationNameChanged = (e) => {
    var index = e.target.getAttribute("data-index");
    var declaration = this.state.declarations[index];

    declaration.name = e.target.value;
    var newDeclarations = this.state.declarations;

    newDeclarations.splice(index, 1, declaration);

    this.setState({ declarations: newDeclarations });
  };

  declarationUnitValueChanged = (e) => {
    var index = e.target.getAttribute("data-index");
    var declaration = this.state.declarations[index];

    declaration.unitValue = parseFloat(e.target.value);
    declaration.totalValue = declaration.unitValue * declaration.quantity;

    var newDeclarations = this.state.declarations;

    newDeclarations.splice(index, 1, declaration);
    this.setState({ declarations: newDeclarations });
    this.updateDeclarationTotalValue();
  };

  declarationQuantityChanged = (e) => {
    var index = e.target.getAttribute("data-index");
    var declaration = this.state.declarations[index];

    declaration.quantity = parseFloat(e.target.value);
    declaration.totalValue = declaration.unitValue * declaration.quantity;

    var newDeclarations = this.state.declarations;

    newDeclarations.splice(index, 1, declaration);

    this.setState({ declarations: newDeclarations });
    this.updateDeclarationTotalValue();
  };

  getPersonDetails = (details) => {
    console.log(details);
    var items = [];
    if (!details) return items;

    if (details.name && details.name.trim() !== "")
      items.push(<div> {details.name}</div>);
    if (details.company && details.company.trim() !== "")
      items.push(<div> {details.company}</div>);
    if (details.address && details.address.trim() !== "")
      items.push(<div> {details.address}</div>);
    if (details.address2 && details.address2.trim() !== "")
      items.push(<div> {details.address2}</div>);
    if (details.address3 && details.address3.trim() !== "")
      items.push(<div> {details.address3}</div>);
    if (details.city && details.city.trim() !== "")
      items.push(<div> {details.city}</div>);
    if (details.state && details.state.trim() !== "")
      items.push(<div> {details.state}</div>);
    if (details.zipCode && details.zipCode.trim() !== "")
      items.push(<div> {details.zipCode}</div>);
    if (details.country && details.country.trim() !== "")
      items.push(<div> {details.country}</div>);

    return items;
  };

  backToRates = () => {
    this.setState({ currentSection: "ratesList" });
  };

  paymentTypeChanged = (e) => {
    var newQuoteData = { ...this.state.quoteData };
    var oldPaymentType;
    newQuoteData.paymentType = e.target.id;

    var modifiedState = {};
    modifiedState.quoteData = newQuoteData;

    if (
      newQuoteData.paymentType === "card" &&
      oldPaymentType !== newQuoteData.paymentType
    ) {
      var newRateDetails = this.state.rateDetails.map((r) => {
        var price = parseFloat(r.priceRange);
        r.priceRange = price * 1.035;
        r.pickupCost = (r.priceRange / 100) * r.pickupCharge;
        return r;
      });

      var newSelectedRate = this.state.selectedRate;
      var price = parseFloat(newSelectedRate.priceRange);
      newSelectedRate.priceRange = price * 1.035;
      newSelectedRate.pickupCost =
        (newSelectedRate.priceRange / 100) * newSelectedRate.pickupCharge;

      modifiedState.rateDetails = newRateDetails;
      modifiedState.selectedRate = newSelectedRate;
    } else if (
      newQuoteData.paymentType === "cash" &&
      oldPaymentType !== newQuoteData.paymentType
    ) {
      var newRateDetails = this.state.rateDetails.map((r) => {
        var price = parseFloat(r.priceRange);
        r.priceRange = price / 1.035;
        r.pickupCost = (r.priceRange / 100) * r.pickupCharge;
        return r;
      });
      var newSelectedRate = this.state.selectedRate;
      var price = parseFloat(newSelectedRate.priceRange);
      newSelectedRate.priceRange = price / 1.035;
      newSelectedRate.pickupCost =
        (newSelectedRate.priceRange / 100) * newSelectedRate.pickupCharge;

      modifiedState.rateDetails = newRateDetails;
      modifiedState.selectedRate = newSelectedRate;
    }

    this.setState(modifiedState);
  };

  licenseAccepted = (e) => {
    console.log("license", e.target.checked);
    this.setState({ licenseAccepted: e.target.checked });
  };

  confirmBooking = async (e) => {
    if (!this.state.licenseAccepted) {
      alert("Please accept terms and conditions");
      return;
    }

    console.log(this.state);
    var data = {
      consignee: this.state.consigneeDetails,
      consignor: this.state.consignorDetails,
      rate: this.state.selectedRate,
      quoteData: this.state.quoteData,
      packages: this.state.packages,
      declarations: {
        items: this.state.declarations.filter((d) => d.name),
        totalValue: this.state.declarationValue,
        bags: this.state.declarationBags,
        weight: this.state.declarationWeight,
      },
    };
    // debugger;
    var res = await createShipment(data);
    console.log(res);

    if (res.success) {
      // Download PDF
      const doc = (
        <OrderConfirm
          sender={this.state.consignorDetails}
          receiver={this.state.consigneeDetails}
          items={this.state.declarations}
          bags={this.state.declarationBags}
          totVal={this.state.declarationValue}
          totWeight={this.state.declarationWeight}
          packages={this.state.packages}
          quoteData={this.state.quoteData}
        />
      );
      const asPdf = pdf([]); // [] is important, throws without an argument
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(blob, "order_summary.pdf");
      if (this.state.quoteData.paymentType === "card") {
        console.log(res);

        const data = res.data.order;
        this.props.history.push(
          `/payment?name=${data.consignorName}&email=${data.consignorEmailAddress}&address=${data.consignorAddress}, ${data.consignorAddress2}, ${data.consignorAddress3}&city=${data.consignorCity}&phone=${data.consignorPhoneNumber}&id=${res.data.id}`
        );
      } else {
        this.props.history.push("/");
      }

      return;
    }

    alert("Something went wrong :(");
  };

  disableCity = () => {
    if (this.state.currentSection === "senderDetails") {
      return true;
    } else if (
      this.state.currentSection === "receiverDetails" &&
      this.state.quoteData.shipmentType === "Domestic"
    ) {
      return true;
    }

    return false;
  };

  termsAndConditions = () => {
    const handleClose = () => this.setState({ tandcModalOpen: false });
    const handleShow = () => this.setState({ tandcModalOpen: true });

    console.log(this.state.tandcModalOpen);

    return (
      <>
        <a href="javascript:;" onClick={handleShow}>
          Terms and Conditions
        </a>
        <Modal
          size="xl"
          dialogClassName="modal-dialog-lg"
          scrollable
          show={this.state.tandcModalOpen}
          onHide={handleClose}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Terms and Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container" style={{ width: "95%" }}>
              <div className="row" style={{ color: "black" }}>
                <b style={{ marginTop: "10px" }}>CONDITIONS OF CARRIAGE</b>
                In tendering the shipment for carriage, the customer agrees to
                these terms and conditions of carriage and that this air bill is
                NON-NEGOTIABLE and has been prepared by the customer or on the
                customer&#39;s behalf by PARCEL.LK. As used in these conditions,
                PARCEL.LK includes PARCEL LANKA (PVT)LTD, all operating
                divisions and subsidiaries of PARCEL LANKA (PVT)LTD. and their
                respective agents, servants, officers and employees.
                <b style={{ marginTop: "10px" }}>1. SCOPE OF CONDITIONS</b>
                These conditions shall govern and apply to all services provided
                by PARCEL.LK, BY SIGNING THIS AIRBILL, THE CUSTOMER ACKNOWLEDGES
                THAT HE/SHE HAS READ THESE CONDITIONS AND AGREES TO BE BOUND BY
                EACH OF THEM. PARCEL.LK shall not be bound by any agreement
                which varies from these conditions, unless such agreement is in
                writing and signed by an authorized officer of PARCEL.LK. In the
                absence of such written agreement, these conditions shall
                constitute the entire agreement between PARCEL.LK and each of
                its customers. NO employee of PARCEL.LK shall have the authority
                to alter or waive these terms and conditions, except as stated
                herein.
                <b style={{ marginTop: "10px" }}>
                  2. PARCEL.LK&#39;S OBLIGATIONS
                </b>
                PARCEL.LK agrees, subject to payment of applicable rates and
                charges in effect on the date of acceptance by PARCEL.LK of a
                customer&#39;s shipment, to arrange for the transportation of
                the shipment between the locations agreed upon by PARCEL.LK and
                the customer. PARCEL.LK reserves the right to transport the
                customer&#39;s shipment by any route and procedure and by
                successive carriers and according to its own handling, storage
                and transportation methods.
                <b style={{ marginTop: "10px" }}>3. SERVICE RESTRICTION</b>
                a) PARCEL.LK reserves the right to refuse any documents or
                parcels from any person, firm, or company at its own discretion.
                <br />
                b) PARCEL.LK reserves the right to abandon carriage of any
                shipment at any time after acceptance when such shipment could
                possibly cause damage or delay to other shipments, equipment or
                personnel, or when any such carriage is prohibited by law or is
                in violation of any of the rules contained herein.
                <br />
                c) PARCEL.LK reserves the right to open and inspect any shipment
                consigned by a customer to ensure that it is capable of carriage
                to the state or country of destination within the standard
                customs procedures and handling methods of PARCEL.LK. In
                exercising this right, PARCEL.LK does not warrant that any
                particular item to be carried is capable of carriage, without
                infringing the law of any country or state through which the
                item may be carried.
                <b style={{ marginTop: "10px" }}>4. LIMITATION OF LIABILITY</b>
                Subject to Section 5 and 6 hereof:
                <br />
                a) PARCEL.LK will be responsible for the customer&#39;s shipment
                only while it is within PARCEL.LK&#39;S custody and control.
                PARCEL.LK shall not be liable for loss or damage of a shipment
                while reshipment is out of PARCEL.LK&#39;S custody or control.
                PARCEL.LK&#39;S LIABILITY IS IN ANY EVENT LIMITED TO TWENTY
                DOLLARS (US$20/=) (DOMESTIC) HUNDRED DOLLARS (US$100/=)
                (INTERNATIONAL) or its equivalent per shipment unless a higher
                value is declared on the Airway bill at the time of tender and
                an additional charge is paid for, as assessed and determined by
                PARCEL.LK, for each twenty Dollars (DOM - US$20/=) / (INT -
                US$100/=) or fraction thereof, by which the insured value
                designated by the customer on the Airway bill exceeds twenty
                Dollars (DOM - US$20/=) / (INT - US$100/=) per shipment.
                <br />
                b) Notwithstanding the foregoing, should the customer, at the
                time of tender, declare a higher value than twenty Dollars (DOM
                - US$20.00) / hundred dollars (INT - US$100/=) on the Airway
                bill, PARCEL.LK&#39;S liability shall in any event be limited to
                the lower of the insured value or the amount of any loss or
                damage actually sustained by the customer.
                <br />
                c) The actual value of a shipment shall be ascertained by
                reference to its replacement, reconstitution or reconstruction
                value at the time and place of shipment, whichever is less,
                without reference to its commercial utility to the customer or
                to other items of consequential loss.
                <br />
                d) NOTWITHSTANDING ANY OF THE FOREGGOING, THE MAXIMUM INSURED
                VALUE ON ANY SHIPMENT ACCEPTED BY PARCEL.LK IS FIVE THOUSAND
                DOLLARS (US$5,000.00) AND IN NO EVENT SHALL THE LIABILITY OF
                PARCEL.LK EXCEED THAT AMOUNT.
                <br />
                <b style={{ marginTop: "10px" }}>
                  5. CONEQUENTIAL DAMAGES EXCLUDED
                </b>
                PARCEL.LK SHALL NOT BE LIABLE, IN ANY EVENT,, FOR ANY
                CONSEQUENTIAL OR SPECIAL OR INCIDENTAL DAMAGE OR OTHER INDIRECT
                LOSS HOWEVER AIRSING, WHETHER OR NOT PARCEL.LK HAD KNOWLEDGE
                THAT SUCH DAMAGE MIGHT BE INCURRED, INCLUDING, BUT NOT LIMITED
                TO LOSS OF INCOME, PROFITS, INTEREST, ULITITY OR LOSS OF MARKET.
                <b style={{ marginTop: "10px" }}>6. LIABILITY NOT ASSUMED</b>
                a) PARCEL.LK shall be not liable for any loss, damage, delay,
                misdelivery, nondelivery not caused by its own negligence, or
                for any loss, damage, delay, misdelivery or non-delivery caused
                by:
                <br />
                i. the act, default or omission the shipper or consignee or any
                other party who claims an interest in the shipment.
                <br />
                ii. the nature of the shipment or any defect, characteristic, or
                inherent vice thereof.
                <br />
                iii. violation by the shipper or consignee of any term or
                condition stated herein including, but not limited to, improper
                or insufficient packing, securing, marking or addressing,
                misdescribing the contents of any shipment or failure to observe
                any of these rules relating to the shipments not acceptable for
                transportation whether such rules are now or hereafter
                promulgated by PARCEL.LK. iv. Acts of God, perils of the air,
                enemies, public authorities acting with actual or apparent
                authority or law, acts or omission of postal, customs or other
                government officials, riots, strikes, or other local disputes,
                hazard incidents to a state of war, weather conditions,
                temperature or atmospheric changes or conditions, mechanical or
                other delay, of any aircraft used in providing transportation
                services or any other cause reasonably beyond the control of
                PARCEL.LK.
                <br />
                v. Acts or omissions of any postal service, forwarder, or any
                other entity to whom a shipment is tendered by PARCEL.LK for
                transportation, regardless of whether the shipper requested or
                had knowledge of such third party delivery requirement. vi.
                Electrical or magnetic injury, erasure, or other such damage to
                electronic or photographic images or recordings in any form, or
                damage due to insects or vermin.
                <br />
                b) While PARCEL.LK will endeavor to exercise its best efforts to
                provide expeditious delivery in accordance with regular delivery
                schedules, PARCEL.LK will not under any circumstances be liable
                for delay in pickup, transportation or delivery of any shipment
                regardless of the causes of such delay
                <b style={{ marginTop: "10px" }}>
                  7. MATERIALS NOT ACCEPTABLE FOR TRANSPORT
                </b>
                a) PARCEL.LK will notify customer from time to time as to
                certain classes of materials which are not accepted by PARCEL.LK
                for carriage. It is the customer&#39;s responsibility to
                accurately describe the shipment on this Air bill and to ensure
                that no material is delivered to PARCEL.LK which has been
                declared to be unacceptable by PARCEL.LK.
                <br />
                b) PARCEL.LK will not carry:
                <br />
                i. property, the carriage of which is prohibited by any law,
                regulation or state or local government of any country from, to
                or through which the property maybe carried: and
                <br />
                firearms bullion works of art negotiable instruments in bearer
                form
                <br />
                jewelry precious metals precious stones lewd obscene or
                pornographic material
                <br />
                currency stamps deeds hazardous or combustible material
                <br />
                cashier&#39;s checks money orders travelers checks industrial
                carbon and diamonds
                <br />
                antiques plants animals
                <br />
                c) In the event that any customer should consign to PARCEL.LK
                any such item, as described above, or any item which the
                customer has undervalued for customs purposes or misdiscribed,
                whether intentionally or otherwise the customer shall indemnify
                and hold PARCEL.LK harmless from all claims, damages, fines and
                expenses arising in connection therewith, and PARCEL.LK shall
                have the right to abandon such property and / or release
                possession of said property to any agent or employee of any
                national or local government claiming jurisdiction over such
                materials. Immediately upon PARCEL.LK&#39;S obtaining knowledge
                that such materials infringing these conditions have been turned
                over to PARCEL.LK shall be free to exercise any of its rights
                reserved to it under this section without incurring liability
                whatsoever to the customer.
                <br />
                <b style={{ marginTop: "10px" }}>8. PACKAGING</b>
                The packaging of the customer&#39;s documents or goods for
                transportation is the customer&#39;s sole responsibility,
                including the placing of the goods or documents in any container
                which may be supplied by the customer to PARCEL.LK. PARCEL.LK
                accepts no responsibility for loss or damage to documents or
                goods caused by inadequate or inappropriate packaging. It is the
                sole responsibility of the customer to address adequately each
                consignment of documents or goods to enable effective delivery
                to be made. PARCEL.LK shall not be liable for delay in
                forwarding or delivery resulting from the customer&#39;s failure
                to comply with its obligations in this respect.
                <b style={{ marginTop: "10px" }}>9. NEGLIGENCE</b>
                The customer is liable for all losses, damages and expenses
                arising as a result of its failure to comply with its
                obligations under this agreement as a result of its negligence.
                <b style={{ marginTop: "10px" }}>10. CHARGES</b>
                Any rates quoted by PARCEL.LK for carriage are inclusive of
                local airport taxes, but exclusive of any value added taxes,
                duties, levies, imposts, deposits or outlays incurred in respect
                of carriage of the customer&#39;s goods. Should the customer
                indicate by endorsement in the space provided on the airbill
                that the receiver shall be liable for any customs duty, the
                customer shall be liable for such customs duty in the event of a
                default in payment by the receiver. PARCEL.LK will not be liable
                for any penalties imposed or loss or damage incurred due to the
                customer&#39;s documents or goods being impounded by customs or
                similar authorities and the customer hereby indemnifies
                PARCEL.LK against such penalty or loss.
                <b style={{ marginTop: "10px" }}>11. PROPERTY</b>
                PARCEL.LK will only carry documents or goods which are the
                property of the customer and the customer warrants that it is
                authorized to accept and is accepting these conditions not only
                on behalf of itself but as agent and on behalf of all other
                persons who are or may hereafter be interested in the documents
                or goods. The customer hereby undertakes to indemnify PARCEL.LK
                against any damages, costs and expenses resulting from any
                breach of this warranty.
                <b style={{ marginTop: "10px" }}>12. CLAIMS</b>
                ANY CLAIMS AGAINST PARCEL.LK MUST BE SUBMITTED IN WRITING TO THE
                OFFICE OF PARCEL.LK NEAREST THE LOCATION WHERE THE SJHIPMENT WAS
                ACCEPTED, WITHIN SIXTY (60 DAYS) OF THE DATE OF ACCEPTANCE BY
                PARCEL.LK.
                <b style={{ marginTop: "10px" }}>
                  13. NON-DELIVERY OF SHIPMENT
                </b>
                Notwithstanding the shipper&#39;s instruction to the contrary,
                the shipper shall be liable for all costs and expenses related
                to the shipment of the package, and for costs incurred in either
                returning the shipment or warehousing the shipment pending
                disposition.
                <b style={{ marginTop: "10px" }}>14. INSURANCE:</b>
                a) PARCEL.LK maintains cargo liability insurance to the full
                extent of the liability offered to the shipper.
                <br />
                b) At the request of the shipper and upon payment therefore at
                the then prevailing rates. PARCEL.LK will arrange insurance
                coverage on behalf of the shipper in an amount not exceeding
                Five Thousand Dollars (5,000.00)
                <br />
                c) The insurance cover shall be governed by all the terms and
                conditions contained in the policy of insurance issued by
                insurance carrier. A certificate evidencing such insurance will
                be made available to the shipper.
                <br />
                d) CONSEQUENTIAL DAMAGES AND LOSS OR DAMAGE RESULTING FROM
                DELAYS IN TRANSPORTATION ARE NOT COVERED BY ANY SUCH POLICY OF
                INSURANCE.
                <br />
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
          <button variant="secondary" onClick={handleClose}>
            Close
          </button>
          
        </Modal.Footer> */}
        </Modal>
      </>
    );
  };

  detailsSection = () => (
    <div
    >
      <h3 style={{ color: "#8E211A" }}>
        {this.state.currentSection === "receiverDetails"
          ? "Receiver Details"
          : "Sender Details"}
      </h3>
      <div
        style={{
          color: "black",
          background: "#f9f9f9",
          border: "1px solid gray",
          borderRadius: "5px",
          padding: "40px 20px",
        }}
      >
        <div className="row">
          <div className="col-md-3">
            <Form.Group>
              <Form.Label>
                First Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={this.state.currentDetails.firstName || ""}
                onChange={this.currentDetailsFirstNameChange}
                required
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group>
              <Form.Label>
                Last Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={this.state.currentDetails.lastName || ""}
                onChange={this.currentDetailsLastNameChange}
                required
              />
            </Form.Group>
          </div>
          <div className="col-md-6">
            <Form.Group>
              <Form.Label>
                NIC/Passport 
                {(this.state.currentSection === 'senderDetails' ? <span style={{ color: "red" }}>*</span> : <span></span>)}
              </Form.Label>
              <Form.Control
                type="text"
                value={this.state.currentDetails.nic || ""}
                onChange={this.currentDetailsNicChange}
                required={(this.state.currentSection === 'senderDetails' ? true : false)}
              />
            </Form.Group>
          </div>
          <div className="col-md-6">
            <Form.Group>
              <Form.Label>Company</Form.Label>
              <Form.Control
                type="text"
                value={this.state.currentDetails.company || ""}
                onChange={this.currentDetailsCompanyChange}
              />
            </Form.Group>
          </div>
          <div className="col-md-6">
            <Form.Group>
              <Form.Label>
                Country <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                as="select"
                value={this.state.currentDetails.countryCode || "AFG"}
                onChange={this.currentDetailsCountryChange}
                required
                disabled
              >
                {this.renderCountriesOptionList(countries)}
              </Form.Control>
            </Form.Group>
          </div>
          <div className="col-md-6">
            <Form.Group>
              <Form.Label>
                Address <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={this.state.currentDetails.address || ""}
                onChange={this.currentDetailsAddressChange}
                required
              />
            </Form.Group>
          </div>
          <div className="col-md-6">
            <Form.Group>
              <Form.Label>Address 2</Form.Label>
              <Form.Control
                type="text"
                value={this.state.currentDetails.address2 || ""}
                onChange={this.currentDetailsAddress2Change}
              />
            </Form.Group>
          </div>
          <div className="col-md-6">
            <Form.Group>
              <Form.Label>Address 3</Form.Label>
              <Form.Control
                type="text"
                value={this.state.currentDetails.address3 || ""}
                onChange={this.currentDetailsAddress3Change}
              />
            </Form.Group>
          </div>
              <div className="col-md-2">
                <Form.Group>
                  <Form.Label>
                    ZipCode/Suburb <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={this.state.currentDetails.zipCode || ""}
                    onChange={this.currentDetailsZipCodeChange}
                    required
                  />
                </Form.Group>
              </div>
              <div className="col-md-2">
                <Form.Group>
                  <Form.Label>
                    City <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={this.state.currentDetails.city || ""}
                    onChange={this.currentDetailsCityChange}
                    required
                    disabled={this.disableCity()}
                  />
                </Form.Group>
              </div>
              <div className="col-md-2">
                <Form.Group>
                  <Form.Label>State </Form.Label>
                  <Form.Control
                    type="text"
                    value={this.state.currentDetails.state || ""}
                    onChange={this.currentDetailsStateChange}
                  />
                </Form.Group>
              </div>
          <div className="col-md-6">
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={this.state.currentDetails.email || ""}
                onChange={this.currentDetailsEmailChange}
              />
            </Form.Group>
          </div>
          <div class="col-md-2">
            <Form.Group>
              <Form.Label>Mobile Number 1</Form.Label>
              <Form.Control
                type="tel"
                value={this.state.currentDetails.mobileNumber1 || ""}
                onChange={this.currentDetailsMobileNumber1Change}
              />
            </Form.Group>
          </div>
          <div class="col-md-2">
            <Form.Group>
              <Form.Label>Mobile Number 2</Form.Label>
              <Form.Control
                type="tel"
                value={this.state.currentDetails.mobileNumber2 || ""}
                onChange={this.currentDetailsMobileNumber2Change}
              />
            </Form.Group>
          </div>
          <div class="col-md-2">
            <Form.Group>
              <Form.Label>Landline</Form.Label>
              <Form.Control
                type="tel"
                value={this.state.currentDetails.landline || ""}
                onChange={this.currentDetailsLandlineChange}
              />
            </Form.Group>
          </div>
        </div>
      </div>
    </div>
  );

  packageDetailsSection = () => (
    <div
    //   style={{
    //     display: `${
    //       this.state.currentSection === "packageDetails" || true
    //         ? "inherit"
    //         : "none"
    //     }`,
    //   }}
    >
      <h3 style={{ color: "#8E211A" }}>Package details</h3>
      <div
        style={{
          color: "black",
          background: "#f9f9f9",
          border: "1px solid gray",
          borderRadius: "5px",
          padding: "40px 20px",
        }}
      >
        <div className="row" style={{ margin: "10px 0" }}>
          <div className="col-3">Boxes</div>
          <div className="col-2">Quantity</div>
          <div className="col-2">Weight (Kg)</div>
          <div className="col-4">Dimensions (cm)</div>
          <div className="col-1"></div>
        </div>
        <hr
          style={{
            border: "1px black",
            height: "1px",
            width: "100%",
            margin: "0px 0px 10px 0px",
            color: "black",
            background: "#aeaeae54",
          }}
        />
        {this.state.packages.map(this.packagesCard)}

        <Form.Group>
          <div
            className="form-group"
            style={{ textAlign: "center", padding: "10px 0" }}
          >
            <button
              style={{
                fontSize: "12px",
                color: "white",
                width: "100px",
                float: "right",
              }}
              className="btn-1"
              onClick={this.addPackage}
            >
              Add
            </button>
          </div>
        </Form.Group>
      </div>
    </div>
  );

  itemDeclarationSection = () => {
    console.log("itemDeclaration", this.state.currentSection);
    return (
      <div
      // style={{
      //   display: `${
      //     this.state.currentSection === "itemDeclaration" ? "inherit" : "none"
      //   }`,
      // }}
      >
        <h3 style={{ color: "#8E211A" }}>Item declaration</h3>
        <div
          style={{
            color: "black",
            background: "#f9f9f9",
            border: "1px solid gray",
            borderRadius: "5px",
            padding: "40px 20px",
          }}
        >
          <div className="row" style={{ margin: "10px 0" }}>
            <div className="col">Item</div>
            <div className="col-2">Quantity</div>
            <div className="col-2">Unit Value</div>
            <div className="col-2">Total Value</div>
            <div className="col-1"></div>
          </div>
          <hr
            style={{
              border: "1px black",
              height: "1px",
              width: "100%",
              margin: "0px 0px 10px 0px",
              color: "black",
              background: "#aeaeae54",
            }}
          />
          {this.state.declarations.map(this.declarationCard)}
          <div className="row">
            <div className="col">
              <Form.Group>
                <div
                  className="form-group"
                  style={{ textAlign: "center", padding: "10px 0" }}
                >
                  <button
                    style={{
                      fontSize: "12px",
                      color: "white",
                      width: "100px",
                      float: "right",
                    }}
                    className="btn-1"
                    onClick={this.addDeclaration}
                  >
                    Add
                  </button>
                </div>
              </Form.Group>
            </div>
          </div>

          <div className="row" style={{ margin: "0px 10px" }}>
            <div className="col">
              <Form.Group>
                <Form.Label>Total Value</Form.Label>
                <Form.Control
                  type="number"
                  value={this.state.declarationValue || 0}
                  min="0"
                />
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group>
                <Form.Label>Total Weight</Form.Label>
                <Form.Control
                  type="number"
                  value={this.state.declarationWeight || 0}
                  onChange={this.declarationWeightChanged}
                  min="0"
                />
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group>
                <Form.Label>Number of Boxes/Bags</Form.Label>
                <Form.Control
                  type="number"
                  value={this.state.declarationBags || 0}
                  onChange={this.declarationBagsChanged}
                  min="1"
                />
              </Form.Group>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="wrapper">
        <Header />
        <section className="container">
          <div>
            <h3 style={{ textAlign: "center", color: "white" }}>{"."}</h3>
            <div
              style={{
                display: `${
                  this.state.currentSection === "" ? "none" : "inherit"
                }`,
              }}
            >
              <ul className="navbar-nav theme-menu noHover h5">
                <li
                  class={
                    this.state.currentSection === "ratesList" ? "active" : null
                  }
                >
                  {" "}
                  <a>1. Select Service</a>
                </li>
                <li
                  class={
                    this.state.currentSection === "senderDetails"
                      ? "active"
                      : null
                  }
                >
                  {" "}
                  <a>2. Sender Details</a>
                </li>
                <li
                  class={
                    this.state.currentSection === "receiverDetails"
                      ? "active"
                      : null
                  }
                >
                  {" "}
                  <a>3. Receiver Details</a>{" "}
                </li>
                <li
                  class={
                    this.state.currentSection === "itemDeclaration"
                      ? "active"
                      : null
                  }
                >
                  {" "}
                  <a>4. Item Declaration</a>
                </li>
                <li
                  class={
                    this.state.currentSection === "packageDetails"
                      ? "active"
                      : null
                  }
                >
                  {" "}
                  <a>5. Package Details</a>
                </li>
                <li
                  class={
                    this.state.currentSection === "orderSummary"
                      ? "active pt-2 pl-3 ml-3"
                      : "pt-2 pl-3 ml-3"
                  }
                >
                  {" "}
                  <a>6. Confirm Order</a>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section
          className="container"
          style={{
            display: `${this.state.currentSection !== "" ? "none" : "inherit"}`,
          }}
        >
          <div style={{}}>
            <div class="loader"></div>
            <div
              style={{
                textAlign: "center",
                marginTop: "10px",
                fontSize: "1.2em",
              }}
            >
              loading
            </div>
          </div>
        </section>
        <section
          className="container"
          style={{
            display: `${
              this.state.currentSection !== "ratesList" ? "none" : "inherit"
            }`,
          }}
        >
          <div id="service-select">
            <div>
              <h3 style={{ textAlign: "center" }}>
                Select your preferred service
              </h3>
            </div>
            {this.state.rateDetails.map((l, i) =>
              this.shippingServiceCard(l, i)
            )}
            {this.state.rateDetails.length == 0 ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "1.2em",
                  color: "#8E211A",
                  margin: "30px",
                }}
              >
                <p>Couldn't find any rates :(</p>
                <a href="/"> Go Back</a>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </section>
        <section
          className="container"
          style={{
            display: `${
              this.state.currentSection === "senderDetails" ||
              this.state.currentSection === "receiverDetails" ||
              this.state.currentSection === "packageDetails" ||
              this.state.currentSection === "itemDeclaration"
                ? "inherit"
                : "none"
            }`,
          }}
        >
          <div>
            <Form onSubmit={this.handleSubmit} validated={this.validated}>
              {/* Personal details section :- this is reused to get both sender and receiver details */}
              {this.state.currentSection === "senderDetails" ||
              this.state.currentSection === "receiverDetails" ? (
                <this.detailsSection />
              ) : null}
              {/* Package details section */}
              {this.state.currentSection === "packageDetails" ? (
                <this.packageDetailsSection />
              ) : null}

              {/* Declaration Form */}
              {this.state.currentSection === "itemDeclaration" ? (
                <this.itemDeclarationSection />
              ) : null}
              {/* Next button */}
              <Form.Group>
                <div
                  className="form-group"
                  style={{ textAlign: "center", padding: "10px 0" }}
                >
                  <button
                    style={{
                      fontSize: "15px",
                      color: "white",
                      width: "200px",
                      float: "left",
                    }}
                    className="btn-1"
                    onClick={this.goBack}
                  >
                    Back
                  </button>
                  <button
                    style={{
                      fontSize: "15px",
                      color: "white",
                      width: "200px",
                      float: "right",
                    }}
                    type="submit"
                    className="btn-1"
                    onClick={this.detailsSubmit}
                  >
                    Next
                  </button>
                </div>
              </Form.Group>
            </Form>
          </div>
        </section>
        <section
          className="container"
          style={{
            display: `${
              this.state.currentSection === "orderSummary" ? "inherit" : "none"
            }`,
          }}
        >
          <div>
            <h3 style={{ color: "#8E211A" }}>Order Summary</h3>
            <div
              style={{
                color: "black",
                background: "#f9f9f9",
                border: "1px solid gray",
                borderRadius: "5px",
                padding: "80px 80px",
              }}
            >
              <div
                className="row"
                style={{
                  backgroundColor: "white",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  borderRadius: "5px",
                  padding: "30px 10px",
                }}
              >
                <div className="col-1"></div>
                <div className="col">
                  <div>
                    {" "}
                    <b>From</b>
                  </div>
                  {this.getPersonDetails(this.state.consignorDetails)}
                </div>
                <div className="col-2">
                  <Media
                    object
                    style={{
                      border: "none",
                      maxHeight: "50px",
                      maxWidth: "70px",
                    }}
                    className="img-fluid align-self-center"
                    src="../assets/img/icons/rightArrow.png"
                    alt="pic"
                  />
                </div>
                <div className="col">
                  <div>
                    {" "}
                    <b>To</b>
                  </div>
                  {this.getPersonDetails(this.state.consigneeDetails)}
                </div>
                <div className="col-2">
                  <Form.Group>
                    <div
                      className="form-group"
                      style={{ textAlign: "center", padding: "10px 0" }}
                    >
                      <button
                        style={{
                          fontSize: "12px",
                          color: "white",
                          width: "100px",
                          float: "right",
                          padding: "10px 0",
                        }}
                        className="btn-1"
                        onClick={this.backToRates}
                      >
                        Edit
                      </button>
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="row" style={{ marginTop: "25px" }}>
                    <div className="col">
                      <Form.Label style={{ marginRight: "25px" }}>
                        Payment Method
                      </Form.Label>
                      <Form.Check
                        style={{ marginRight: "25px" }}
                        defaultChecked
                        inline
                        type="radio"
                        name="groupOptions"
                        label="Cash"
                        id="cash"
                        onChange={this.paymentTypeChanged}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        name="groupOptions"
                        label="Card"
                        id="card"
                        onChange={this.paymentTypeChanged}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="col"
                  style={{
                    float: "right",
                    fontSize: "1.5em",
                    color: "#8E211A",
                    fontWeight: "500",
                    margin: "30px 0px",
                  }}
                >
                  <div style={{ float: "right" }}>
                    Total : {this.getPrice().toLocaleString()} LKR
                  </div>
                  <br />
                  {this.state.quoteData.pickupType &&
                  this.state.quoteData.pickupType === "Pickup" ? (
                    <div style={{ float: "right" }}>
                      {" "}
                      Pickup charges : {this.getPickupCost().toLocaleString()}{" "}
                      LKR
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
              <div
                className="row"
                style={{
                  fontSize: "1.8em",
                  color: "#8E211A",
                  fontWeight: "500",
                  marginTop: "30px",
                }}
              >
                <div className="col">
                  <div style={{ float: "right" }}>
                    Sub total : {this.getTotalPrice().toLocaleString()} LKR
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: "20px", color: "black" }}>
            <div className="col">
              <div className="row" style={{ fontSize: "1.1em" }}>
                <div className="col col-auto" style={{ paddingRight: "0" }}>
                  <Form.Check
                    inline
                    type="checkbox"
                    name="license"
                    label="Accept"
                    id="accepted"
                    onChange={this.licenseAccepted}
                  />
                </div>
                <div className="col col-auto" style={{ paddingLeft: "0" }}>
                  <this.termsAndConditions />
                  {/* <a rel="noopener noreferrer" href="/tandc" target="_blank" >
                    Terms and Conditions
                  </a> */}
                </div>
              </div>
            </div>
            <div className="col">
              <Form.Group>
                <div
                  className="form-group"
                  style={{ textAlign: "center", padding: "10px 0" }}
                >
                  <button
                    style={{
                      fontSize: "12px",
                      color: "white",
                      width: "200px",
                      float: "right",
                      padding: "10px 0",
                    }}
                    className="btn-1"
                    onClick={this.confirmBooking}
                  >
                    Confirm Booking
                  </button>

                  {/* {this.state.currentSection === "orderSummary" &&
                    this.state.packages?.length > 0 && (
                      <PDFDownloadLink
                        document={
                          <OrderConfirm
                            sender={this.state.consignorDetails}
                            receiver={this.state.consigneeDetails}
                            items={this.state.declarations}
                            bags={this.state.declarationBags}
                            totVal={this.state.declarationValue}
                            totWeight={this.state.declarationWeight}
                            packages={this.state.packages}
                            quoteData={this.state.quoteData}
                          />
                        }
                        fileName="order_summary.pdf"
                        style={{
                          fontSize: "12px",
                          color: "white",
                          width: "200px",
                          float: "right",
                          padding: "10px 0",
                          marginRight: 10,
                        }}
                        className="btn-1"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading..." : "Print Order Summary"
                        }
                      </PDFDownloadLink>
                    )} */}
                </div>
              </Form.Group>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default BookingForm;

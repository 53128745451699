import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf",
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  topSection: { display: "flex", flexDirection: "row" },
  logoSection: {
    width: "100%",
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 5,
  },
  logoView: { height: "4vh" },
  title: { fontFamily: "Open Sans", fontWeight: 700, fontSize: 12 },
  fieldView: { marginTop: 7 },
  fieldLabelView: { width: "37%" },
  fieldValueView: { width: "63%" },
  fieldText: { fontFamily: "Open Sans", fontSize: 10 },
  fieldValue: { fontFamily: "Open Sans", fontSize: 10 },
  pl5: { paddingLeft: 10 },
  flexRow: { display: "flex", flexDirection: "row" },
});

const {
  logoSection,
  logoView,
  title,
  fieldLabelView,
  fieldValueView,
  fieldView,
  fieldText,
  fieldValue,
  pl5,
  flexRow,
} = styles;

export default function OrderConfirm({
  sender,
  receiver,
  items = [],
  bags = 0,
  totVal = 0,
  totWeight = 0,
  packages,
  quoteData,
}) {
  return (
    <Document>
      <Page size="A4" style={{ paddingTop: 30, paddingBottom: 30 }}>
        {/* Heading */}
        <View style={{ ...logoSection }}>
          <View style={{ ...logoView }}>
            <Image src="assets/img/logo/GTVLogo.png" height="100%" />
          </View>
          <Text style={{ ...pl5, ...title }}>
            GTV Express Online Booking - Declaration Form
          </Text>
        </View>

        <View
          style={{
            ...flexRow,
            borderBottom: "1px solid black",
            paddingRight: 20,
            paddingLeft: 20,
          }}
        >
          {/* Sender */}
          <View style={{ width: "50%", paddingBottom: 11 }}>
            <View style={{}}>
              <View className={{ ...fieldView }}>
                <Text style={{ ...fieldText, fontWeight: 700 }}>Sender</Text>
              </View>
              <View>
                <View style={{ ...flexRow, ...fieldView }}>
                  <View style={{ ...fieldLabelView }}>
                    <Text style={{ ...fieldText }}>Name *</Text>
                  </View>
                  <View style={{ ...fieldValueView }}>
                    <Text style={{ ...fieldValue }}>{sender?.firstName} {sender?.lastName}</Text>
                  </View>
                </View>
                <View style={{ ...flexRow, ...fieldView }}>
                  <View style={{ ...fieldLabelView }}>
                    <Text style={{ ...fieldText }}>Company</Text>
                  </View>
                  <View style={{ ...fieldValueView }}>
                    <Text style={{ ...fieldValue }}>{sender?.company}</Text>
                  </View>
                </View>
                <View style={{ ...flexRow, ...fieldView }}>
                  <View style={{ ...fieldLabelView }}>
                    <Text style={{ ...fieldText }}>Country *</Text>
                  </View>
                  <View style={{ ...fieldValueView }}>
                    <Text style={{ ...fieldValue }}>{sender?.country}</Text>
                  </View>
                </View>
                <View style={{ ...flexRow, ...fieldView }}>
                  <View style={{ ...fieldLabelView }}>
                    <Text style={{ ...fieldText }}>Address *</Text>
                  </View>
                  <View style={{ ...fieldValueView }}>
                    <Text style={{ ...fieldValue }}>{sender?.address}</Text>
                  </View>
                </View>
                <View style={{ ...flexRow, ...fieldView }}>
                  <View style={{ ...fieldLabelView }}>
                    <Text style={{ ...fieldText }}>Address 2</Text>
                  </View>
                  <View style={{ ...fieldValueView }}>
                    <Text style={{ ...fieldValue }}>{sender?.address2}</Text>
                  </View>
                </View>
                <View style={{ ...flexRow, ...fieldView }}>
                  <View style={{ ...fieldLabelView }}>
                    <Text style={{ ...fieldText }}>Address 3</Text>
                  </View>
                  <View style={{ ...fieldValueView }}>
                    <Text style={{ ...fieldValue }}>{sender?.address3}</Text>
                  </View>
                </View>
                <View style={{ ...flexRow, ...fieldView }}>
                  <View style={{ ...fieldLabelView }}>
                    <Text style={{ ...fieldText }}>ZipCode/Suburb *</Text>
                  </View>
                  <View style={{ ...fieldValueView }}>
                    <Text style={{ ...fieldValue }}>{sender?.zipCode}</Text>
                  </View>
                </View>
                <View style={{ ...flexRow, ...fieldView }}>
                  <View style={{ ...fieldLabelView }}>
                    <Text style={{ ...fieldText }}>City *</Text>
                  </View>
                  <View style={{ ...fieldValueView }}>
                    <Text style={{ ...fieldValue }}>{sender?.city}</Text>
                  </View>
                </View>
                <View style={{ ...flexRow, ...fieldView }}>
                  <View style={{ ...fieldLabelView }}>
                    <Text style={{ ...fieldText }}>State</Text>
                  </View>
                  <View style={{ ...fieldValueView }}>
                    <Text style={{ ...fieldValue }}>{sender?.state}</Text>
                  </View>
                </View>
                <View style={{ ...flexRow, ...fieldView }}>
                  <View style={{ ...fieldLabelView }}>
                    <Text style={{ ...fieldText }}>Email</Text>
                  </View>
                  <View style={{ ...fieldValueView }}>
                    <Text style={{ ...fieldValue }}>{sender?.email}</Text>
                  </View>
                </View>
                <View style={{ ...flexRow, ...fieldView }}>
                  <View style={{ ...fieldLabelView }}>
                    <Text style={{ ...fieldText }}>Mobile Number 1</Text>
                  </View>
                  <View style={{ ...fieldValueView }}>
                    <Text style={{ ...fieldValue }}>
                      {sender?.mobileNumber1}
                    </Text>
                  </View>
                </View>
                <View style={{ ...flexRow, ...fieldView }}>
                  <View style={{ ...fieldLabelView }}>
                    <Text style={{ ...fieldText }}>Mobile Number 2</Text>
                  </View>
                  <View style={{ ...fieldValueView }}>
                    <Text style={{ ...fieldValue }}>
                    {sender?.mobileNumber2}
                    </Text>
                  </View>
                </View>
                <View style={{ ...flexRow, ...fieldView }}>
                  <View style={{ ...fieldLabelView }}>
                    <Text style={{ ...fieldText }}>Landline</Text>
                  </View>
                  <View style={{ ...fieldValueView }}>
                    <Text style={{ ...fieldValue }}>
                    {sender?.landline}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          {/* Divider */}
          <View
            style={{
              borderLeft: "1px solid black",
              marginLeft: 10,
              marginRight: 10,
            }}
          />

          {/* Receiver */}
          <View style={{ width: "50%", paddingBottom: 11 }}>
            <View style={{}}>
              <View className={{ ...fieldView }}>
                <Text style={{ ...fieldText, fontWeight: 700 }}>Receiver</Text>
              </View>
              <View>
                <View style={{ ...flexRow, ...fieldView }}>
                  <View style={{ ...fieldLabelView }}>
                    <Text style={{ ...fieldText }}>Name *</Text>
                  </View>
                  <View style={{ ...fieldValueView }}>
                    <Text style={{ ...fieldValue }}>{receiver?.firstName} {receiver?.lastName}</Text>
                  </View>
                </View>
                <View style={{ ...flexRow, ...fieldView }}>
                  <View style={{ ...fieldLabelView }}>
                    <Text style={{ ...fieldText }}>Company</Text>
                  </View>
                  <View style={{ ...fieldValueView }}>
                    <Text style={{ ...fieldValue }}>{receiver?.company}</Text>
                  </View>
                </View>
                <View style={{ ...flexRow, ...fieldView }}>
                  <View style={{ ...fieldLabelView }}>
                    <Text style={{ ...fieldText }}>Country *</Text>
                  </View>
                  <View style={{ ...fieldValueView }}>
                    <Text style={{ ...fieldValue }}>{receiver?.country}</Text>
                  </View>
                </View>
                <View style={{ ...flexRow, ...fieldView }}>
                  <View style={{ ...fieldLabelView }}>
                    <Text style={{ ...fieldText }}>Address *</Text>
                  </View>
                  <View style={{ ...fieldValueView }}>
                    <Text style={{ ...fieldValue }}>{receiver?.address}</Text>
                  </View>
                </View>
                <View style={{ ...flexRow, ...fieldView }}>
                  <View style={{ ...fieldLabelView }}>
                    <Text style={{ ...fieldText }}>Address 2</Text>
                  </View>
                  <View style={{ ...fieldValueView }}>
                    <Text style={{ ...fieldValue }}>{receiver?.address2}</Text>
                  </View>
                </View>
                <View style={{ ...flexRow, ...fieldView }}>
                  <View style={{ ...fieldLabelView }}>
                    <Text style={{ ...fieldText }}>Address 3</Text>
                  </View>
                  <View style={{ ...fieldValueView }}>
                    <Text style={{ ...fieldValue }}>{receiver?.address3}</Text>
                  </View>
                </View>
                <View style={{ ...flexRow, ...fieldView }}>
                  <View style={{ ...fieldLabelView }}>
                    <Text style={{ ...fieldText }}>ZipCode/Suburb *</Text>
                  </View>
                  <View style={{ ...fieldValueView }}>
                    <Text style={{ ...fieldValue }}>{receiver?.zipCode}</Text>
                  </View>
                </View>
                <View style={{ ...flexRow, ...fieldView }}>
                  <View style={{ ...fieldLabelView }}>
                    <Text style={{ ...fieldText }}>City *</Text>
                  </View>
                  <View style={{ ...fieldValueView }}>
                    <Text style={{ ...fieldValue }}>{receiver?.city}</Text>
                  </View>
                </View>
                <View style={{ ...flexRow, ...fieldView }}>
                  <View style={{ ...fieldLabelView }}>
                    <Text style={{ ...fieldText }}>State</Text>
                  </View>
                  <View style={{ ...fieldValueView }}>
                    <Text style={{ ...fieldValue }}>{receiver?.state}</Text>
                  </View>
                </View>
                <View style={{ ...flexRow, ...fieldView }}>
                  <View style={{ ...fieldLabelView }}>
                    <Text style={{ ...fieldText }}>Email</Text>
                  </View>
                  <View style={{ ...fieldValueView }}>
                    <Text style={{ ...fieldValue }}>{receiver?.email}</Text>
                  </View>
                </View>
                <View style={{ ...flexRow, ...fieldView }}>
                  <View style={{ ...fieldLabelView }}>
                    <Text style={{ ...fieldText }}>Mobile Number 1</Text>
                  </View>
                  <View style={{ ...fieldValueView }}>
                    <Text style={{ ...fieldValue }}>
                      {receiver?.mobileNumber1}
                    </Text>
                  </View>
                </View>
                <View style={{ ...flexRow, ...fieldView }}>
                  <View style={{ ...fieldLabelView }}>
                    <Text style={{ ...fieldText }}>Mobile Number 2</Text>
                  </View>
                  <View style={{ ...fieldValueView }}>
                    <Text style={{ ...fieldValue }}>
                    {receiver?.mobileNumber2}
                    </Text>
                  </View>
                </View>
                <View style={{ ...flexRow, ...fieldView }}>
                  <View style={{ ...fieldLabelView }}>
                    <Text style={{ ...fieldText }}>Landline</Text>
                  </View>
                  <View style={{ ...fieldValueView }}>
                    <Text style={{ ...fieldValue }}>
                    {receiver?.landline}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>

        {/* Table Columns */}
        <View
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            paddingRight: 20,
            paddingLeft: 20,
            ...flexRow,
          }}
        >
          <View style={{ width: "45%" }}>
            <Text style={{ ...fieldText }}>Item</Text>
          </View>
          <View style={{ width: "15%", textAlign: "center" }}>
            <Text style={{ ...fieldText }}>Quantity</Text>
          </View>
          <View style={{ width: "20%", textAlign: "right" }}>
            <Text style={{ ...fieldText }}>Unit Value</Text>
          </View>
          <View style={{ width: "20%", textAlign: "right" }}>
            <Text style={{ ...fieldText }}>Total Value</Text>
          </View>
        </View>

        {/* Table Data */}
        {items?.map((item, i) => (
          <View
            key={`item-${i}`}
            style={{
              paddingTop: 5,
              paddingRight: 20,
              paddingLeft: 20,
              ...flexRow,
            }}
          >
            <View style={{ width: "45%" }}>
              <Text style={{ ...fieldValue }}>{item?.name}</Text>
            </View>
            <View style={{ width: "15%", textAlign: "center" }}>
              <Text style={{ ...fieldValue }}>
                {item?.quantity
                  ?.toString()
                  .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
              </Text>
            </View>
            <View style={{ width: "20%", textAlign: "right" }}>
              <Text style={{ ...fieldValue }}>
                Rs.{" "}
                {item?.unitValue
                  ?.toFixed(2)
                  ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
              </Text>
            </View>
            <View style={{ width: "20%", textAlign: "right" }}>
              <Text style={{ ...fieldValue }}>
                Rs.{" "}
                {item?.totalValue
                  ?.toFixed(2)
                  ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
              </Text>
            </View>
          </View>
        ))}

        {/* Totals */}
        <View
          style={{
            ...flexRow,
            padding: 20,
            borderBottom: "1px solid black",
          }}
        >
          <View style={{ width: "33%" }}>
            <Text style={{ ...fieldText }}>
              Total Value - Rs.{" "}
              {totVal?.toFixed(2)?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
            </Text>
          </View>
          <View style={{ width: "35%", textAlign: "center" }}>
            <Text style={{ ...fieldText }}>
              Total Weight -{" "}
              {totWeight?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}{" "}
              Kg
            </Text>
          </View>
          <View style={{ width: "33%", textAlign: "right" }}>
            <Text style={{ ...fieldText }}>
              Number of Boxes/Bags - {bags} Box
            </Text>
          </View>
        </View>

        {/* Measurements */}
        {packages?.map((box, i) => (
          <View key={`box-${i}`} style={{ ...flexRow, padding: 20 }}>
            {/* <View style={{ width: "20%" }}>
            <Text style={{ ...fieldText }}>Boxes - {packages?.length}</Text>
          </View> */}
            <View style={{ width: "30%" }}>
              <Text style={{ ...fieldText }}>Quantity - {box.quantity}</Text>
            </View>
            <View style={{ width: "30%" }}>
              <Text style={{ ...fieldText }}>
                Weight (Kg) -{" "}
                {box.weight?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
              </Text>
            </View>
            <View style={{ width: "40%" }}>
              <Text style={{ ...fieldText }}>
                Dimensions (cm) - {box.x} x {box.y} x {box.z}
              </Text>
            </View>
          </View>
        ))}

        {/* Options */}
        <View style={{ ...flexRow }}>
          <View style={{ width: "50%", paddingBottom: 10 }}>
            <View style={{ paddingLeft: 20 }}>
              <View className={{ ...fieldView }}>
                <Text style={{ ...fieldText, fontWeight: 700 }}>
                  Service Type:
                </Text>
              </View>
            </View>
          </View>
          <View style={{ width: "50%", paddingBottom: 10 }}>
            <View style={{ paddingLeft: 20 }}>
              <View className={{ ...fieldView }}>
                <Text style={{ ...fieldText, fontWeight: 700 }}>
                  Total Amount:
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/* Options 2 */}
        <View
          style={{
            ...flexRow,
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 10,
            paddingTop: 10,
          }}
        >
          <View style={{ width: "33%" }}>
            <Text style={{ ...fieldText }}>
              Payment Type - {quoteData?.paymentType}
            </Text>
          </View>
          <View style={{ width: "35%" }}>
            <Text style={{ ...fieldText }}>
              Parcel Type: {quoteData?.parcelType}
            </Text>
          </View>
          <View style={{ width: "33%" }}>
            <Text style={{ ...fieldText }}>Mode: {quoteData?.pickupType}</Text>
          </View>
        </View>

        {/* Date */}
        <View
          style={{
            ...flexRow,
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 10,
            paddingTop: 10,
          }}
        >
          <View style={{ width: "25%" }}>
            <Text style={{ ...fieldText }}>
              Date:{" "}
              {new Date().getDate() > 9
                ? new Date().getDate()
                : `0${new Date().getDate()}`}
              /
              {new Date().getMonth() > 9
                ? new Date().getMonth()
                : `0${new Date().getMonth()}`}
              /{new Date().getFullYear()}
            </Text>
          </View>
          <View style={{ width: "25%", textAlign: "center" }}>
            <Text style={{ ...fieldText }}>
              Time:{" "}
              {new Date().getHours() > 9
                ? new Date().getHours()
                : `0${new Date().getHours()}`}
              :
              {new Date().getMinutes() > 9
                ? new Date().getMinutes()
                : `0${new Date().getMinutes()}`}
              :
              {new Date().getSeconds() > 9
                ? new Date().getSeconds()
                : `0${new Date().getSeconds()}`}
            </Text>
          </View>
        </View>

        {/* Agreement */}
        <View style={{ padding: 20 }}>
          <Text style={{ ...fieldText }}>
            I / We declare I/We have access to the web and that I/ We have read
            and understood the terms and conditions hosted at www.gtve.com/tc .
            Furthermore, I or we certify and confirm that the infomation given
            in this form is true and accurate. Rates are subject to exchange
            rate and fuel surcharge fluctuations GTV reserves the right to
            change or amend the rates in case of any fluctuations after booking.
          </Text>
          <Text style={{ ...fieldText }}>
            Please produce this booking confirmation to the officer / agent at
            the time of handing over the parcel / document.
          </Text>
        </View>

        {/* Seal */}
        <View
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 10,
            paddingTop: 10,
          }}
        >
          <Text style={{ ...fieldText }}>{`Signature & Seal -`}</Text>
        </View>
      </Page>
    </Document>
  );
}

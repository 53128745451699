import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga'

import './App.css';
import home from './Pages/home';
import Aboutus from './Pages/about';
import service from './Pages/services';
import contact from './Pages/contact';
import FAQ from './Pages/faq';
import tracking from './Pages/tracking';
import notFound from './Pages/404';
import locations from './Pages/locations'
import bookingForm from './Pages/bookingForm';
import TermsAndConditions from './Pages/termsAndConditions';
import Payment from './Pages/Payment'


function initializerAnalytics() {
  ReactGA.initialize("UA-44879633-10")
  ReactGA.pageview('/home')
}

function App() {
  initializerAnalytics();
  return (
  <div className="wrapper">
   
     <Router>
        <Switch>
          <Route exact path="/" component={home} />
          <Route path="/aboutus" component={Aboutus} />
          <Route path="/services" component={service} />
          <Route path="/contact" component={contact} />
          <Route path="/faq" component={FAQ} />
          <Route path="/tracking/:referenceNumber" component={tracking} />
          <Route path="/locations" component={locations}/>
          <Route path="/booking" component={bookingForm}/>
          <Route path="/tandc" component={TermsAndConditions}/>
          <Route path="/payment" component={Payment}/>
          <Route path="*" component={notFound}/>
          
        </Switch>
     </Router>
    
  </div>
  );
}

export default App;

import React from 'react'
import { BrowserRouter as Link } from "react-router-dom";


import Header from '../Components/Header/Header'
import Footer from '../Components/Footer/Footer'
class Contact extends React.Component {
    render() {
        return (
            <div className="wrapper">
                <Header />
                <div className="container">

                    {/* header section h1 and all */}

                    <section className="pad-50">
                        <div className="theme-container container ">
                            <div className="row">
                                <div className="col-sm-8 pull-left">
                                    <div className="title-wrap">
                                        <h2 className="section-title no-margin"> contact us </h2>
                                        <p className="fs-16 no-margin"> Get in touch with us easily </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                    {/* end of header section */}

                    {/* details area */}

                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-6">

                            <div>
                                <ul className="contact-detail title-2 pt-50">
                                    <li className="wow fadeInUp" data-wow-offset="50" data-wow-delay=".30s" style={{ visibility: "visible" }}>
                                        <span>Address:</span> <p className="gray-clr"> GTV Enterprises Private Limited<br /> No. 18/3
                                        E.A. Cooray Mawatha, Wellawatte, <br /> Colombo 00600. </p>
                                    </li>
                                    <li className="wow fadeInUp" data-wow-offset="50" data-wow-delay=".40s" style={{ visibility: "visible" }} >
                                        <span>Number (call Center): </span> <p className="gray-clr"> +94 114 654 444 </p>
                                    </li>
                                    <li className="wow fadeInUp" data-wow-offset="50" data-wow-delay=".50s" style={{ visibility: "visible" }} >
                                        <span>Email address : </span> <p className="gray-clr"> info@gtventer.com</p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        {/* end of details area */}

                        {/* send message form */}

                        <div className="col-sm-12 col-md-12 col-lg-6">

                            <div className="col-md-offset-1 contact-form">
                                <div className="calculate-form">
                                    <form className="row" id="contact-form">
                                        <div className="form-group wow fadeInUp" data-wow-offset="50" data-wow-delay=".30s" style={{ visibility: "visible" }}>
                                            <div > <label className="title-2"> Name: </label></div>
                                            <div> <input type="text" name="Name" id="Name" required="" placeholder="" className="form-control" required /> </div>
                                        </div>

                                        <div className="form-group wow fadeInUp" data-wow-offset="50" data-wow-delay=".30s" style={{ visibility: "visible" }}>
                                            <div > <label className="title-2"> Email: </label></div>
                                            <div> <input type="text" name="Email" id="Email" required="" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" placeholder="" className="form-control" required /> </div>
                                        </div>

                                        <div className="form-group wow fadeInUp" data-wow-offset="50" data-wow-delay=".30s" style={{ visibility: "visible" }}>
                                            <div > <label className="title-2"> Phone: </label></div>
                                            <div> <input type="text" name="Phone" id="Phone" placeholder="" className="form-control" required /> </div>
                                        </div>

                                        <div className="form-group wow fadeInUp" data-wow-offset="50" data-wow-delay=".30s" style={{ visibility: "visible" }}>
                                            <div > <label className="title-2"> Message: </label></div>
                                            <div> <textarea className="form-control" name="Message" id="Message" required="" cols="25" rows="3" required></textarea> </div>
                                        </div>

                                        <div className="form-group wow fadeInUp" data-wow-offset="50" data-wow-delay=".30s" style={{ visibility: "visible" }}>
                                            <div className="pull-right">
                                                <button name="submit" id="submit_btn" className="btn-1"> send message </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                        {/* end of send message form */}

                    </div>

                </div><hr/>
                <Footer />
            </div>
        );
    }
}
export default Contact